import { action, computed, makeObservable, observable, runInAction } from "mobx";

import moment from "moment";

import { Contract, Service, Status } from "./models";

import { ContractsApiV0 } from "../api";

class ContractStore {
  // этот поиск перенести на бекенд
  @observable contracts = new Map(); // контракты
  @observable foundContractsMap = new Map(); // это контракты в работе, найдены на клиенте

  @observable statusesMap = new Map();
  @observable servicesMap = new Map();

  @observable pendingStatuses = false;
  @observable pending = false;
  @observable rootStore = null;

  @observable contractsTotalCount = 0;

  @observable pendingRequests = 0;

  @observable summary = {
    amount: 0,
  };

  constructor(root) {
    // актуальное
    makeObservable(this);
    this.root = root;
    this.apiContractsV0 = new ContractsApiV0(root.authStore);
    // старое
    this.rootStore = root;
  }

  // ----

  /**
   * Создать новый контракт.
   *
   * @param {object} data данные нового контракта
   * @returns
   */
  @action async createContract(data) {
    const payload = { ...data };

    // TODO: ai: перенести все проверки в uploadDocuments
    if (payload.documents && payload.documents.length) {
      let documentIds = undefined;
      if (payload.documents) {
        // TODO: uploadDocuments должен быть в DocumentStore
        documentIds = await this.rootStore.uploadDocuments(data.documents);
      }
      payload.documents = documentIds;
    } else {
      payload.documents = [];
    }

    const created = await this.apiContractsV0.createContract(payload.branchId, payload);

    // TODO: Там нет ошибки, там только данные
    if (created.status && created.detail && (created.status >= 300 || created.status < 200)) {
      return created.detail;
    }
    return this.addActiveContract(created);
  }

  /**
   * Отредактировать контракт.
   *
   * @param {Contract} contract экземпляр контракта
   * @param {object} data обновленные данные контракта
   * @returns
   */
  @action async editContract(contract, data) {
    if (data.documents && data.documents.length) {
      const docIds = [];
      const docFiles = [];
      data.documents.forEach((doc) => {
        if (doc && doc.id) {
          docIds.push(doc.id);
        } else {
          docFiles.push(doc);
        }
      });
      const documentIdsArray = await this.root.uploadDocuments(docFiles);
      data.documents = [...docIds, ...documentIdsArray];
    } else {
      data.documents = [];
    }

    const updateData = {
      ...data,
      id: this.id,
      // date: moment(data.date, "L").format("YYYY-MM-DD"),
      // value: {
      //   amount: Number(data.value),
      //   currency: this.currency,
      // },
      // expensesPlan: {
      //   amount: Number(data.expensesPlan),
      //   currency: this.currency,
      // },
    };
    try {
      const props = await this.apiContractsV0.editContract(contract.id, updateData);
      let updated;
      runInAction(() => {
        updated = contract.update(props);
        if (this.contracts.has(`${updated.id}`)) {
          this.contracts.set(`${updated.id}`, updated);
        }
      });

      // if (props.status && props.detail && (props.status >= 300 || props.status < 200)) {
      //   return props.detail;
      // }
      return updated;
    } catch (error) {
      return error;
    }
  }

  /**
   * Change contract's state
   *
   * @param {Contract} contract model instance for update
   * @returns
   */
  @action async changeContractState(contract, state) {
    const data = await this.apiContractsV0.changeContractStatus(contract.id, state.id);
    runInAction(() => {
      const stateName = data.state;
      // В ответе полный контракт, но мы ставим только имя т.к. везде работаем пока через имя статуса
      contract.state = stateName;
    });
    return contract;
  }

  /**
   * Запросить записи истории контракта.
   *
   * @param {Contract} contract
   * @returns raw response data
   */
  @action async retrieveContractHistory(contract) {
    const data = await this.apiContractsV0.getContractHistoryRecords(contract.id);
    return data;
  }

  /**
   * Запросить статусы договор для филиала.
   *
   * @param {integer} branchId
   */
  // TODO: убрать обработку ошибок в API-класс.
  @action async getStatuses(branchId) {
    this.setPendingStatuses(true);
    this.statusesMap.clear();
    try {
      const statusesData = await this.apiContractsV0.getStatuses(branchId);
      statusesData.forEach((status) => {
        this.addStatus(status);
      });
    } catch (error) {
      this.rootStore.setError("error", null, error && error.message);
    }
    this.setPendingStatuses();
  }

  @action getStatus(id) {
    return this.statusesMap.get(id);
  }

  @action addStatus(data) {
    const status = new Status(data, this);
    this.statusesMap.set(status.id, status);
  }

  /**
   * Запросить активные контракты.
   *
   * На доске только те контракты что сейчас в работе.
   *
   * @param {number} branchId
   * @param {*} ids
   */
  @action async getActiveContracts(branchId, searchBy) {
    runInAction(() => {
      this.pendingContracts++;
    });
    this.contracts.clear();
    this.rootStore.facilityStore.clearFacilities();
    try {
      const contractsData = await this.apiContractsV0.getActiveContracts(branchId, searchBy);
      const { contracts, summary } = contractsData;
      contracts.forEach((contract) => {
        this.addActiveContract(contract);
      });
      runInAction(() => {
        this.summary.amount = summary.amount;
      });
    } catch (error) {
      this.rootStore.setError("error", null, error && error.message);
    }
    runInAction(() => {
      this.pendingContracts--;
    });
  }

  /**
   * Запросить контракты для таблицы контрактов.
   *
   * @param {number} branchId
   */
  @action async findContracts(branchId, searchBy, states, daterange, pagination) {
    runInAction(() => {
      this.pendingContracts++;
      this.contracts.clear();
    });
    const [contractsData, totalCount] = await this.apiContractsV0.findContracts(
      branchId,
      searchBy,
      states,
      daterange,
      pagination
    );
    const { contracts, summary } = contractsData;
    runInAction(() => {
      this.contractsTotalCount = totalCount;
      contracts.forEach((item) => {
        this.addContract(item);
      });
      this.summary.amount = summary.amount;
      this.pendingContracts--;
    });
  }

  // @action async getFoundContracts(branchId, ids) {
  //   this.setPendingContracts(true);
  //   const contracts = await this.api.getFoundContracts(branchId, ids);
  //   contracts.forEach((item) => {
  //     this.addFoundContract(item);
  //   });

  //   this.setPendingContracts();
  // }

  // TODO: зачем оно?
  // @action async getContractsAsync(branchId, ids) {
  //   try {
  //     const contractsData = await this.api.getContracts(branchId, ids, this.rootStore.isLocal);
  //     contractsData.forEach((contract) => {
  //       console.log(contract);
  //       this.addContract(contract);
  //     });
  //   } catch (error) {
  //     this.rootStore.setError("error", null, error && error.message);
  //   }
  // }

  // @action async fetchScannings(branchId, withFinished, withResult, from, to) {
  //   this.setPending(true);
  //   const employeeId = this.rootStore.authStore.userId;
  //   await this.rootStore.facilityStore.fetchScannings(branchId, employeeId, withFinished, withResult, from, to);
  //   this.setPending();
  // }

  async fetchRenderings(branchId) {
    const employeeId = this.rootStore.id;
    this.servicesMap.clear();
    this.setPending(true);
    await this.rootStore.facilityStore.fetchRenderings(branchId, employeeId);
    this.setPending();
  }

  @action
  async fetchScannerById(id) {
    return null;
  }
  @action
  async fetchRendererById(id) {
    return null;
  }

  @action
  getServiceByUid(id) {
    return this.servicesMap.get(id);
  }

  /**
   * Добавить контракт в активные.
   *
   * @param {object} data
   */
  @action addActiveContract(data) {
    const contract = new Contract(data, this);
    this.contracts.set(`${contract.id}`, contract);
    return contract;
  }

  // На самом деле мы не хотим их одновременно. Должно быть одно хранилище контрактов.
  /**
   * Добавить контракт в стор.
   * @param {object} data
   */
  @action addContract(data) {
    const contract = new Contract(data, this);
    this.contracts.set(contract.id, contract);
  }

  @action addFoundContract(data) {
    const contract = new Contract(data, this, true);
    this.foundContractsMap.set(contract.id, contract);
  }

  // TODO: в другой стор
  @action async deleteScanPlanByIdAsync(data) {
    await this.api.deleteScanPlan(data);
    this.deleteScanPlanById(data.planUid);
  }

  @action
  deleteScanPlanById(uid) {
    this.servicesMap.delete(uid);
  }

  /**
   * Получить контракт по идентификатору.
   *
   * @param {number} id
   * @returns Contract instance
   */
  @action getContractById(id) {
    return this.contracts.get(`${id}`);
  }

  @action
  getFoundContractById(id) {
    return this.foundContractsMap.get(`${id}`);
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPendingStatuses(pending = false) {
    this.pendingStatuses = pending;
  }

  @action setPendingState(state, pending = false) {
    const status = this.statusesMap.get(state);
  }

  @action
  getStatusById(id) {
    return this.statusesMap.get(id) || {};
  }

  @computed
  get scanningIdsArray() {
    const array = [];
    this.servicesMap.forEach((service) => {
      if (service.kind === "scanning") {
        array.push(service.uid);
      }
    });
    return array;
  }

  @computed get scanningMyIdsArray() {
    const facilities = this.rootStore.facilityStore.facilitiesByUserId[`${this.rootStore.authStore.userId}`];
    return facilities;

    // this.servicesMap.forEach((service) => {
    //   if (service.kind === "scanning" && `${service.employeeId}` === `${this.rootStore.id}`) {
    //     array.push(service.uid);
    //   }
    // });
  }

  @computed
  get renderingIdsArray() {
    const array = [];
    this.servicesMap.forEach((service) => {
      if (service.kind === "rendering") {
        array.push(service.uid);
      }
    });
    return array;
  }

  @computed get isPending() {
    return this.pendingRequests > 0;
  }

  @computed
  get isPendingStatuses() {
    return this.pendingStatuses;
  }

  @computed get activeContractsArray() {
    const array = [];
    this.contracts.forEach((contract) => {
      array.push(contract);
    });

    return array.sort((a, b) => {
      if (moment(a.date) > moment(b.date)) {
        return -1;
      }
      if (moment(a.date) < moment(b.date)) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Вернуть список контрактов из стора в виде массива.
   */
  @computed get contractsArray() {
    const array = [];
    this.contracts.forEach((contract) => {
      array.push(contract);
    });
    return array;
  }

  // @computed get summary() {
  //   let summary = "";
  //   this.boards.forEach((board) => {
  //     summary += `-${board.id}:${board.totalItemsCount}-`;
  //   });

  //   return summary;
  // }

  // TODO: не понимаю что такое сервисы, унёс это всё в самый низ. Потом разберусь.
  @action
  updateServise(plan) {
    const item = this.servicesMap.get(`${plan.uid}`);
    item.update(plan);
  }

  @action
  clearServises() {
    this.servicesMap.clear();
  }

  @action
  clearServisesByType(type) {
    this.servicesMap.forEach((service, key) => {
      if (service.kind === type) {
        this.servicesMap.delete(key);
      }
    });
  }

  @action
  addService(data) {
    const service = new Service(data, this);
    this.servicesMap.set(service.uid, service);
  }

  // -----

  /**
   * Активные статусы.
   *
   * Такие статусы из которых возможен переход.
   */
  @computed get activeStatuses() {
    const activeStatuses = [];
    for (const status of this.statusesMap.values()) {
      if (!status.isLeaf) activeStatuses.push(status);
    }
    return activeStatuses;
  }

  @computed get statuses() {
    const statuses = [];
    this.statusesMap.forEach((status) => {
      statuses.push(status);
    });
    return statuses;
  }

  /**
   * Сгруппированные по статусу контракты.
   */
  @computed get groupedContracts() {
    const groups = {};
    for (const contract of this.contracts.values()) {
      if (contract.state in groups) {
        groups[contract.state].push(contract);
      } else {
        groups[contract.state] = [contract];
      }
    }
    return groups;
  }
}

export default ContractStore;

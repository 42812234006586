import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { pink } from "@mui/material/colors";
import { Box, Button, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import useStores from "~/hooks";

import Translator from "../components/Translator";
import Preloader from "../components/Preloader";

const Payments = observer(() => {
  const { invoiceStore, branchStore } = useStores();
  const { currentBranchId } = branchStore;
  const { paymentsArray, isPendingPayments } = invoiceStore;

  useLayoutEffect(() => {
    invoiceStore.fetchPayments(currentBranchId);
  }, [invoiceStore, currentBranchId]);

  const columns = [
    { field: "id", headerName: "ID", type: "number", width: 90 },
    {
      field: "date",
      type: "date",
      headerName: <Translator text="Date" />,
      width: 100,
      valueGetter: ({ row }) => row.date.toDate(),
      renderCell: (params) => {
        return <Translator date={params.row.date} />;
      },
    },
    {
      field: "contractNumber",
      headerName: <Translator text="Contract #" />,
      width: 200,
      renderCell: (params) => {
        return params.row && <Translator text={params.row.contractNumber} />;
      },
    },
    {
      field: "invoice",
      headerName: <Translator text="Invoice" />,
      width: 220,
      renderCell: (params) => {
        return !!params.row.invoice ? (
          <Box sx={{ flexDirection: "column", display: "flex" }}>
            <Preloader isPending={params.row.invoice.isPending} />
            {!params.row.invoice.isPending && (
              <Button size="small" sx={{ m: "0.25rem" }}>
                <Translator date={params.row.createdAt} />
                {" - "}
                <Translator number={params.row.amount} currency={params.row.currency} />
              </Button>
            )}
          </Box>
        ) : (
          <Translator text={"No data"} />
        );
      },
    },
    {
      field: "createdAt",
      type: "date",
      headerName: <Translator text="Created at" />,
      width: 100,
      valueGetter: ({ row }) => row.createdAt.toDate(),
      renderCell: (params) => {
        return <Translator date={params.row.createdAt} />;
      },
    },
    {
      field: "methodLabel",
      headerName: <Translator text="Payment method" />,
      width: 100,
      renderCell: (params) => {
        return <Translator text={params.row.invoice && params.row.invoice.methodLabel} />;
      },
    },
    {
      field: "Value",
      type: "number",
      headerName: <Translator text="Value" />,
      width: 125,
      renderCell: (params) => {
        return <Translator number={params.row.amount} currency={params.row.currency} />;
      },
    },
    {
      field: "method.vat",
      headerName: <Translator text="VAT" />,
      width: 50,
      renderCell: (params) => {
        return params.row.invoice.method && params.row.invoice.method.vat;
      },
    },
    {
      field: "valueWithoutVAT",
      headerName: <Translator text="Value without VAT" />,
      width: 150,
      renderCell: (params) => {
        return <Translator number={params.row.valueWithoutVAT} currency={params.row.currency} />;
      },
    },
    {
      field: "isPayerIndividualPerson",
      headerName: <Translator text="Payer is individual person" />,
      width: 150,
      renderCell: (params) => {
        if (params.row.isPayerIndividualPerson) {
          return (
            <Checkbox
              defaultChecked
              disabled
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
          );
        }
        return <Checkbox disabled />;
      },
    },
  ];

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: 1,
      }}
    >
      <DataGrid
        rows={paymentsArray}
        columns={columns}
        loading={isPendingPayments}
        autoPageSize
        pagination
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
});

export default Payments;

import React from "react";
import { observer } from "mobx-react";

import { DataGrid } from "@mui/x-data-grid";

import Translator from "../../components/Translator";
import Preloader from "../../components/Preloader";

import useStores from "~/hooks";

import "./datagrid.css";

/**
 * Таблица счетов и платежей контракта.
 */
const PaymentsGrid = observer(({ invoices }) => {
  const { languageStore } = useStores();

  const columns = [
    // { field: "id", headerName: "ID", type: "number", width: 90 },
    // {
    //   field: "contractNumber",
    //   headerName: languageStore.translate({ text: "Contract #" }),
    //   width: 125,
    //   renderCell: (params) => {
    //     return params.row && <Translator text={params.row.contractNumber} />;
    //   },
    // },
    {
      field: "number",
      type: "string",
      headerName: languageStore.translate({ text: "Invoice Number" }),
      width: 125,
      valueGetter: ({ row }) => row.number,
      renderCell: ({ row }) => <Translator text={row.number} />,
    },
    {
      field: "invoiceCreatedAt",
      type: "date",
      headerName: languageStore.translate({ text: "Invoice created at" }),
      width: 150,
      renderCell: ({ row }) => {
        return <Translator date={row.createdAt} />;
      },
    },
    {
      field: "invoiceAmount",
      type: "number",
      headerName: languageStore.translate({ text: "Invoice amount with VAT" }),
      width: 150,
      renderCell: ({ row }) => {
        return <Translator number={row.amount} currency={row.currency} />;
      },
    },
    {
      field: "invoiceAmountWithoutVAT",
      type: "number",
      headerName: languageStore.translate({ text: "Invoice amount without VAT" }),
      width: 200,
      renderCell: ({ row }) => {
        return <Translator number={row.valueWithoutVAT} currency={row.currency} />;
      },
    },

    // },

    // {
    //   field: "paymentDate",
    //   type: "date",
    //   headerName: languageStore.translate({ text: "Payment date" }),
    //   width: 150,
    //   renderCell: (params) => {
    //     return <Translator date={params.row.date} />;
    //   },
    // },
    // {
    //   field: "paymentDuration",
    //   type: "number",
    //   headerName: languageStore.translate({ text: "Payment duration, days" }),
    //   width: 200,
    //   value: "paymentDuration",
    // },
    // {
    //   field: "paymentCreatedAt",
    //   type: "date",
    //   headerName: languageStore.translate({ text: "Registered at" }),
    //   width: 150,
    //   renderCell: (params) => {
    //     return <Translator date={params.row.createdAt} />;
    //   },
    // },
    // {
    //   field: "registrationDuration",
    //   type: "number",
    //   headerName: languageStore.translate({ text: "Registration duration, days" }),
    //   width: 200,
    //   value: "registrationDuration",
    // },
    // {
    //   field: "amount",
    //   type: "number",
    //   headerName: languageStore.translate({ text: "Payment amount" }),
    //   width: 200,
    //   renderCell: (params) => {
    //     return <Translator money={params.row.amount} />;
    //   },
    // },
    // {
    //   field: "valueWithoutVAT",
    //   headerName: languageStore.translate({ text: "Value without VAT" }),
    //   type: "number",
    //   width: 200,
    //   renderCell: (params) => {
    //     return <Translator money={params.row.valueWithoutVAT} />;
    //   },
    // },
  ];

  /**
   * Динамически вычисляет стиль ячейки таблицы.
   */
  const getCellClassName = (params) => {
    let className = "";
    const { field, value } = params;
    if (field === "paymentDuration" || field === "registrationDuration") {
      if (value === 0) return className;
      // Счёт создали после оплаты
      else if (value < 0) {
        className += "black-cell";
      } else if (value < 7) {
        return "";
      } else if (value >= 7 && value <= 14) {
        return "orange-cell";
      } else {
        return "red-cell";
      }
    }
    return className;
  };

  return (
    <DataGrid
      rows={invoices}
      columns={columns}
      loading={false}
      autoPageSize
      pagination
      getCellClassName={getCellClassName}
    />
  );
});

export default PaymentsGrid;

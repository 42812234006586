import { computed, makeObservable, observable } from "mobx";
import moment from "moment";
import Translator from "../components/Translator";

const typeMapper = {
  "scanm2.clients.Client": "ReportClient",
  "scanm2.structure.Branch": "ReportBranch",
  "scanm2.users.Employee": "ReportUser",
  "compound.date": "date",
  "compound.datetime": "datetime",
  "compound.decimal": "number",
  "scalar.integer": "number",
  "scalar.string": "string",
};

class ReportColumn {
  @observable
  store = null;
  @observable
  index = null;
  @observable
  name = null;
  @observable
  type = null;

  constructor(props, store, index) {
    makeObservable(this);

    this.store = store;
    this.index = index;
    this.name = props.name;
    this.type = props.type;
  }

  @computed
  get fieldType() {
    return typeMapper[this.type] || this.type;
  }

  @computed
  get output() {
    return {
      field: this.name,
      flex: 1,
      headerName: <Translator text={this.name} />,
      type: this.fieldType,
      valueGetter: (params) => {
        if (this.fieldType === "date" || this.fieldType === "datetime") {
          return moment(params.row[this.name].value).toDate();
        }
        return params.row[this.name].value;
      },
      renderCell: ({ row }) => {
        if (this.fieldType === "date" || this.fieldType === "datetime") {
          return <Translator date={row[this.name].value} variant="compact" />;
        }
        return row[this.name].value;
      },
    };
  }
}

export default ReportColumn;

import { action, computed, makeObservable, observable } from "mobx";
import { ModellingPlan } from "../modules/modelling/models";

import { Expense } from "../modules/expenses/models";

/**
 * Объект сканирования и моделирования.
 */
class Facility {
  // данные
  @observable id = null;
  @observable name = null;
  @observable description = null;
  @observable area = null;
  @observable kind = null;
  @observable address = null;

  // связанные сущности
  @observable contract = null;
  @observable scanningPlan = null; // план сканирования
  @observable modellingPlans = []; // планы моделирования
  @observable expenses = []; // расходы по объекту
  @observable contractInfo = null;

  // служебные
  @observable _store = null;

  constructor(store, contract, props) {
    makeObservable(this);

    const { employeesStore, expenseStore, scanningPlansStore } = store.rootStore;
    const { id, name, kind, area, description, address, scanningPlan, modellingPlans, contractInfo } = props;

    this.id = `${id}`; // TODO: строка вместо числа нужна стору, пусть он об этом и думает
    this.name = name;
    this.kind = kind;
    this.area = area;
    this.description = description;
    this.address = address;
    if (!!contractInfo) {
      this.contractInfo = contractInfo;
    } else {
      this.contractInfo = { "id": props.contractId, "number": props.contractNumber };
    }

    // Связанные сущности
    this.contract = contract;
    this.expenses = [];

    if (!!scanningPlan) {
      const scanningEmployee = employeesStore.getEmployeeById(scanningPlan.employeeId);
      this.scanningPlan = scanningPlansStore.addPlan(scanningPlan, this, scanningEmployee);
    }

    this.modellingPlans = modellingPlans.map((p) => {
      const modeller = employeesStore.getEmployeeById(p.modellerId);
      return new ModellingPlan(this, modeller, p);
    });
    this.modellingPlans.sort((a, b) => a.finish >= b.finish);

    // служебные
    this._store = store;

    // Создать все расходы к Объекту
    props.expenses.forEach((expense) => {
      // убедимся что автор есть в сторе
      employeesStore.ensureEmployee(expense.author);
      // создадим расход
      this.expenses.push(
        new Expense({ ...expense, scope: "facility", facility: this, documentsMeta: expense.documentsMeta || expense.documents }, expenseStore)
      );
    });
  }

  // TODO: это в модель плана моделирования объекта
  @action async editModellingPlan(data) {
    const plan = await this._store.api.editModellingPlan(data);
    this._store.processModellingPlan(plan);
  }

  // TODO: это в модель плана моделирования объекта
  @action async setModellingPlanResult(data) {
    const plan = await this._store.api.setModellingPlanResult(data);
    this._store.processModellingPlan(plan);
  }

  /**
   * Label нужен для совместимости интерфейса когда мы
   * отобоажаем один из объектов, например: Facility, Contract или Shift
   */
  @computed get label() {
    return this.name;
  }

  // не нужно, нужно сразу this.contract.state
  @computed get contractState() {
    return this.contract && this.contract.state;
  }

  @computed get services() {
    const array = [];
    this._store.rootStore.contractStore.servicesMap.forEach((service) => {
      if (service.facilityId === this.id) {
        array.push(service);
      }
    });
    return array;
  }

  @computed
  get scanPlan() {
    let plan = null;
    this.services.forEach((service) => {
      if (service.kind === "scanning") {
        plan = service;
      }
    });
    return plan;
  }

  /**
   * Adds expense to facility.
   * 
   * @param {*} expense 
   */
  @action addExpense(expense) {
    this.expenses.push(expense);
  }

  // @computed
  // get modellingPlans() {
  //   const plan = [];
  //   this.services.forEach((service) => {
  //     if (service.kind === "modelling") {
  //       plan.push(service);
  //     }
  //   });
  //   return plan;
  // }

  @computed
  get allModelled() {
    let modelled = true;
    this.modellingPlans.forEach((service) => {
      if (!service.hasResult) {
        modelled = false;
      }
    });
    return modelled;
  }

  @computed
  get value() {
    return this.id;
  }

  // нахуй из модели
  @computed get resultFormConfig() {
    const fields = [];

    fields.push({
      name: "planId",
      type: "hidden",
      initialValue: "payload",
    });

    fields.push({
      name: "url",
      title: "url",
      type: "string",
      isRequired: true,
      isReadOnly: false,
      validate: true,
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Set modelling result",
      formText: "To set result fill in the form below",
      fields,
    };
  }

  // TODO: Убрать нахуй это отсюда
  @computed get editPlanFormConfig() {
    const fields = [];

    fields.push({
      name: "planId",
      type: "hidden",
      initialValue: "payload",
    });

    fields.push({
      name: "facilityId",
      fakeName: "facility",
      title: "Facility",
      type: "select",
      canCreate: false,
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: this.id,
      loading: this._store.isPending || this._store.isPendingRenderingPlans,
      options: [...this._store.facilitiesToPlanModelling, this],
    });

    fields.push({
      name: "modellerId",
      fakeName: "modeller",
      title: "Modeller",
      type: "select",
      canCreate: false,
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: "payload",
      loading: this._store.rootStore.employeesStore.isPending,
      options: this._store.rootStore.employeesStore.renderersArray,
    });

    fields.push({
      name: "start",
      title: "Start",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: "payload",
    });

    fields.push({
      name: "finish",
      title: "finish",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: "payload",
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Edit modelling plan",
      formText: "To edit modelling plan fill in the form below",
      fields,
    };
  }
}

export default Facility;

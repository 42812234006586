import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, ButtonGroup, Divider, Typography } from "@mui/material";

import useStores from "~/hooks";
import GantLine from "./GantLine";
import moment from "moment";
import GantHeader from "./GantHeader";
import { CalendarMonth, ChevronLeft, ChevronRight } from "@mui/icons-material";
import Translator from "../../components/Translator";
import Preloader from "../../components/Preloader";

const GantSchedule = observer(({ role, managerId }) => {
  const { employeesStore, branchStore, facilityStore } = useStores();
  const { branch } = branchStore;
  const { renderersArray } = employeesStore;
  const { isPendingRenderingPlans } = facilityStore;

  const period = "week";
  const [now, setNow] = useState(moment());
  const [start, setStart] = useState(now.clone().startOf(period));
  const [end, setEnd] = useState(now.clone().endOf(period));

  useEffect(() => {
    if (!branch) return;
    const start = now.clone().startOf(period);
    const end = now.clone().endOf(period);
    setStart(start);
    setEnd(end);
    facilityStore.fetchRenderingPlans(
      branch.id,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD")
    );
  }, [now, branch, facilityStore]);

  const onNext = useCallback(() => {
    setNow(now.clone().add(1, period));
  }, [now]);

  const onPrev = useCallback(() => {
    setNow(now.clone().add(-1, period));
  }, [now]);

  const lines = useMemo(() => {
    return renderersArray.map((modeller) => {
      return (
        <GantLine
          key={`${modeller.id}`}
          startOfPeriod={start}
          endOfPeriod={end}
          modeller={modeller}
        />
      );
    });
  }, [renderersArray, start, end]);

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: "0.5rem",
        pt: "1rem",
        overflow: "hidden",
      }}
    >
      <Typography variant="h5">
        <Translator text="Modeller schedule" />
      </Typography>
      <Box sx={{ flexDirection: "row", pt: "0.5rem", pb: "0.5rem" }}>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            sx={{
              p: "0.5rem 0.75rem",
              ".MuiButton-startIcon": {
                margin: 0,
              },
            }}
            startIcon={<ChevronLeft />}
            onClick={onPrev}
          />
          <Button startIcon={<CalendarMonth />} variant="outlined">
            <Typography variant="body">
              <Translator date={start} />
              {" - "}
              <Translator date={end} />
            </Typography>
          </Button>
          <Button
            sx={{
              p: "0.5rem 0.75rem",
              ".MuiButton-endIcon": {
                margin: 0,
              },
            }}
            endIcon={<ChevronRight />}
            onClick={onNext}
          />
        </ButtonGroup>
      </Box>
      <Divider />
      <Preloader isPending={isPendingRenderingPlans} />
      {!isPendingRenderingPlans && (
        <GantHeader key="header" startOfPeriod={start} endofPeriod={end} />
      )}
      {!isPendingRenderingPlans && lines}
    </Box>
  );
});

export default GantSchedule;

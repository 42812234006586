import { computed, makeObservable, observable } from "mobx";

import moment from "moment";

import ModellingResult from "./ModellingResult";

/**
 * Класс плана моделирования.
 */
export default class ModellingPlan {
  @observable id;

  // связанные сущности
  @observable facility;
  @observable modeller;

  /**
   * {
   *   "id": 9,
   *   "facilityId": 37,
   *   "modellerId": 11,
   *   "managerId": 1,
   *   "start": "2022-12-09",
   *   "finish": "2022-12-11",
   *   "isEditable": null,
   *   "result": {
   *       "id": 9,
   *       "url": "https://scanm2.it/it"
   *   }
   * }
   * @param {Facility} facility объект моделирования
   * @param {Employee} modeller отрисовщик
   * @param {object} props объект данных плана
   */
  constructor(facility, modeller, props) {
    makeObservable(this);
    const { id, start, finish, result } = props;

    this.id = id;
    this.start = moment(start);
    this.finish = moment(finish);

    this.facility = facility;
    this.modeller = modeller;
    // результата может не быть, поэтому проверим и если есть - вызовем конструктор
    if (!!result) {
      this.result = new ModellingResult(this, result);
    }
  }

  /**
   * Завершено ли моделирование объекта.
   */
  @computed get isFinished() {
    return this.result?.url !== "";
  }
}

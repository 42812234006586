import React, { useState, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { DataGrid, useGridApiRef } from "~/components/grid";
import { Box, Tooltip } from "@mui/material";
import DocumentAsIcon from "../../components/DocumentAsIcon";
import useStores from "~/hooks";
import Translator from "~/components/Translator";
import CommercialOfferContextMenu from "./components/CommercialOfferContextMenu";
import ChangeOfferStateDialog from "./components/ChangeOfferStateDialog";
import EditCommercialOfferDialog from "./components/EditCommercialOfferDialog";

import { CreateContractDialog } from "~/modules/contracts/components/dialogs";

import "~/modules/payments/datagrid.css";
import URLButton from "~/components/buttons/URLButton";

/**
 * Таблица коммерческих предложений.
 */
const OffersGrid = observer(({ store, searchBy, daterange, selectedStates }) => {
  const { branch } = store.root.branchStore;
  const [isPending, setIsPending] = useState(false);
  const [isCreateContractDialogVisible, setIsCreateContractDialogVisible] = useState(false);

  const [selectedRow, setSelectedRow] = useState();
  const [rowMenuPosition, setRowMenuPosition] = useState([null, null]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  // диалоги
  const [isChangeStateDialogVisible, setIsChangeStateDialogVisible] = useState(false);
  const [isEditCommercialOfferDialogVisible, setIsEditCommercialOfferDialogVisible] = useState(false);
  const { languageStore } = useStores();

  useEffect(() => {
    if (paginationModel.pageSize < 1) return;
    async function fetchData() {
      setIsPending(true);
      await store.loadOffers(branch.id, daterange, searchBy, selectedStates, paginationModel);
      setIsPending(false);
    }
    if (!!branch) fetchData();
  }, [store, branch, searchBy, daterange, selectedStates, paginationModel]);

  const columns = useMemo(
    () => [
      {
        field: "number",
        headerName: languageStore.translate({ text: "Number" }),
        type: "string",
        width: 150,
        sortable: false,
        renderCell: ({ row }) => <Translator text={row.number} />,
      },
      // {
      //   field: "createdAt",
      //   headerName: languageStore.translate({ text: "Created at" }),
      //   type: "date",
      //   width: 100,
      //   sortable: false,
      //   valueGetter: ({ row }) => row.createdAt.toDate(),
      //   renderCell: ({ row }) => <Translator date={row.createdAt} variant="compact" />,
      // },
      {
        field: "date",
        headerName: languageStore.translate({ text: "Date" }),
        type: "date",
        width: 100,
        sortable: false,
        valueGetter: (value) => value.toDate(),
        renderCell: ({ row }) => <Translator date={row.date} variant="compact" />,
      },
      {
        field: "targetDate",
        headerName: languageStore.translate({ text: "Target Date" }),
        type: "date",
        width: 100,
        sortable: false,
        valueGetter: (value) => value.toDate(),
        renderCell: ({ row }) => <Translator date={row.targetDate} variant="compact" />,
      },
      {
        field: "updatedAt",
        headerName: languageStore.translate({ text: "Updated at" }),
        type: "date",
        width: 100,
        sortable: false,
        valueGetter: (value) => value.toDate(),
        renderCell: ({ row }) => <Translator date={row.updatedAt} variant="compact" />,
      },
      {
        field: "author",
        valueGetter: (value) => value?.name,
        headerName: languageStore.translate({ text: "Manager" }),
        type: "string",
        width: 150,
        sortable: false,
        renderCell: ({ row }) => row.author?.name,
      },
      {
        field: "client",
        headerName: languageStore.translate({ text: "Client" }),
        type: "string",
        width: 200,
        sortable: false,
        renderCell: ({ row }) => <Translator text={row.client?.label} />,
      },
      {
        field: "value",
        valueGetter: (value) => value.amount,
        headerName: languageStore.translate({ text: "Value" }),
        type: "number",
        width: 125,
        sortable: false,
        renderCell: ({ row }) => <Translator number={row.value.amount} currency={row.value.currency} />,
      },
      {
        field: "description",
        headerName: languageStore.translate({ text: "Description" }),
        type: "string",
        flex: 1,
        sortable: false,
        minWidth: 200,
        renderCell: ({ row }) => row.description,
      },
      {
        field: "crmLink",
        headerName: languageStore.translate({ text: "CRM" }),
        type: "string",
        width: 100,
        sortable: false,
        renderCell: ({ row }) => {
          return <URLButton name={"AMO"} link={row.crmLink} />;
        },
      },
      {
        field: "documents",
        headerName: languageStore.translate({ text: "Documents" }),
        width: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
            {row.documents.map((doc) => (
              <DocumentAsIcon document={doc} key={doc.id} type="documents" isCompact={true} />
            ))}
          </Box>
        ),
      },
      {
        field: "state",
        headerName: languageStore.translate({ text: "State" }),
        type: "string",
        width: 100,
        sortable: false,
        renderCell: ({ row }) => <Translator text={row.state} />,
      },
      {
        field: "comment",
        headerName: languageStore.translate({ text: "Comment" }),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Tooltip title={row.comment}>{row.comment}</Tooltip>,
      },
    ],
    [languageStore]
  );

  // После редактирования offer не обновится состояние offer c useMemo, поэтому без useMemo!
  const offer = store.offer(selectedRow);

  // ссылка на API таблицы
  const gridApi = useGridApiRef();

  // Открывает ContextMenu
  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      // поставить новую выбранную строку
      const id = Number(event.currentTarget.getAttribute("data-id"));
      setSelectedRow(id);
      // убрать прежние выбранные
      const selectedIds = gridApi.current.getSelectedRows();
      selectedIds.forEach((value, x) => {
        gridApi.current.selectRow(x, false, true);
      });
      gridApi.current.selectRow(id);
      setRowMenuPosition([event.clientX - 2, event.clientY - 4]);
    },
    [gridApi]
  );

  // Закрыть контекстное меню
  const closeContextMenu = useCallback(() => {
    setRowMenuPosition([null, null]);
  }, []);

  // функция раскраски ячейки
  const getCellClassName = (params) => {
    const { field, value, row } = params;
    let className = "";

    if (field === "state") {
      if (value === "accepted") {
        className += "green-cell";
      } else if (value === "postponed") {
        className += "orange-cell";
      } else if (value === "rejected") {
        className += "red-cell";
      }
    }

    if (field === "description") {
      if (!row.description) className += "orange-cell";
    }

    if ((row.state !== "accepted") & (row.state !== "rejected")) {
      if (field === "targetDate") {
        const now = moment();
        const diff = now.diff(value, "days");
        if (diff > 0) {
          className += "red-cell";
        } else if (diff > -2) {
          className += "orange-cell";
        }
      }
    }

    return className;
  };

  const createContractDialog = useMemo(() => {
    return (
      <CreateContractDialog
        initialValues={{ ...offer }}
        isVisible={isCreateContractDialogVisible}
        setIsVisible={setIsCreateContractDialogVisible}
        postCreateFunc={async (contract) => {
          const payload = {
            comment: contract.number,
            state: "accepted",
            targetDate: offer.targetDate.format("YYYY-MM-DD"),
          };
          await store.changeOfferState(offer.id, payload);
        }}
      />
    );
  }, [offer, isCreateContractDialogVisible, store]);

  return (
    <>
      <DataGrid
        apiRef={gridApi}
        rows={store.offers}
        columns={columns}
        rowCount={store.totalCount}
        disableColumnFilter // убрать фильтры клиентские
        disableColumnSorting
        stickyHeader={true}
        pagination={true}
        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
        disableRowSelectionOnClick={false}
        loading={isPending & store.isPending}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getCellClassName={getCellClassName}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 52}
        sx={{
          "& .MuiDataGrid-cell": {
            // чтобы был отступ в ячейке при использовании динамической её высоты
            padding: 1,
            // чтобы текст был по вернхнему краю
            alignItems: "baseline",
          },
        }}
        slotProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: "context-menu" },
          },
        }}
      />
      <ChangeOfferStateDialog
        offer={offer}
        isVisible={isChangeStateDialogVisible}
        setIsVisible={setIsChangeStateDialogVisible}
      />
      <EditCommercialOfferDialog
        offer={offer}
        isVisible={isEditCommercialOfferDialogVisible}
        setIsVisible={setIsEditCommercialOfferDialogVisible}
      />
      {createContractDialog}
      <CommercialOfferContextMenu
        position={rowMenuPosition}
        handleClose={closeContextMenu}
        offer={offer}
        openChangeStateDialog={() => {
          setIsChangeStateDialogVisible(true);
          closeContextMenu();
        }}
        openEditCommercialOfferDialog={() => {
          setIsEditCommercialOfferDialogVisible(true);
          closeContextMenu();
        }}
        openCreateContractDialog={() => {
          setIsCreateContractDialogVisible(true);
          closeContextMenu();
        }}
      />
    </>
  );
});

export default OffersGrid;

/**
 * Модель рабочей смены сканировщика.
 */
import { makeObservable, observable, computed } from "mobx";

import moment from "moment-timezone";
import Document from "~/modules/expenses/models/Document";
import Car from "~/modules/equipment/data/models/Car";
import Scanner from "~/modules/equipment/data/models/Scanner";
import ShiftStartMetaData from "./ShiftStartMetadata";
import ShiftFinishMetaData from "./ShiftFinishMetadata";

class ScannerWorkingShift {
  @observable id;
  @observable startedAt;
  @observable finishedAt;

  @observable startCarPhotos;
  @observable finishCarPhotos;

  @observable startScannerPhotos;
  @observable finishScannerPhotos;

  @observable stationsCount;
  @observable mileage;
  @observable mileageExpense;
  @observable facilityExpense;
  @observable totalExpense;

  @observable employee;
  @observable scanner;
  @observable store;

  @observable facilities;

  @observable startMeta;
  @observable finishMeta;

  constructor(
    store,
    id,
    employee,
    startedAt,
    finishedAt,
    startCarPhotos,
    finishCarPhotos,
    startScannerPhotos,
    finishScannerPhotos,
    car,
    scanner,
    stationsCount,
    mileage,
    mileageExpense,
    facilityExpense,
    shiftExpense,
    totalExpenses,
    facilities,
    startMeta,
    finishMeta
  ) {
    makeObservable(this);
    this.store = store;

    this.id = id;
    this.startedAt = startedAt;
    this.finishedAt = finishedAt;

    this.startCarPhotos = startCarPhotos || [];
    this.finishCarPhotos = finishCarPhotos || [];
    this.startScannerPhotos = startScannerPhotos || [];
    this.finishScannerPhotos = finishScannerPhotos || [];

    this.stationsCount = stationsCount;
    this.mileage = mileage;
    this.mileageExpense = mileageExpense;
    this.facilityExpense = facilityExpense;
    this.shiftExpense = shiftExpense;
    this.totalExpenses = totalExpenses;

    this.employee = employee;
    this.car = car;
    this.scanner = scanner;

    this.facilities = facilities;

    this.startMeta = startMeta;
    this.finishMeta = finishMeta;
  }

  /**
   * Фабричный метод создания экземпляра.
   *
   * Здесь вся логика обработки JSON от сервера.
   *
   * {
   *    "branchId": 5,
   *    "employeeId": 1,
   *    "finishMetada": {
   *        "carMileage": 2701,
   *        "carPhotoIds": [],
   *        "scannerPhotoIds": [],
   *        "stationsCount": 30
   *    },
   *    "finishedAt": "2022-11-02T08:31:24+00:00",
   *    "id": 54,
   *    "role": "scanner",
   *    "startMetadata": {
   *        "carId": 1,
   *        "carMileage": 1400,
   *        "carPhotoIds": [],
   *        "scannerId": 1,
   *        "scannerPhotoIds": [],
   *        "stationsCount": 20
   *    },
   *    "startedAt": "2022-11-02T07:56:03.544989+00:00"
   * },
   *
   * @param {*} store
   * @param {*} json
   * @returns ScannerWorkingShift instance
   */
  static async create(store, json) {
    const { id, employeeId, stationsCount, mileage } = json;
    const { mileageExpense, facilityExpense, shiftExpense, totalExpenses } = json;
    const { facilityInstances } = json;
    let { startedAt, finishedAt } = json;
    startedAt = moment.utc(startedAt);

    finishedAt = finishedAt ? moment.utc(finishedAt) : null;
    const employee = store.root.employeesStore.getEmployeeById(employeeId);

    const startCarPhotos = json.startMetadata?.carPhotos.map((d) => new Document(d));
    const finishCarPhotos = json.finishMetada?.carPhotos?.map((d) => new Document(d));

    const startScannerPhotos = json.startMetadata?.scannerPhotos.map((d) => new Document(d));
    const finishScannerPhotos = json.finishMetada?.scannerPhotos?.map((d) => new Document(d));

    var car;
    if (json.startMetadata?.car) {
      car = new Car(json.startMetadata.car, null);
    }

    var scanner;
    if (json.startMetadata?.scanner) {
      scanner = new Scanner(json.startMetadata.scanner, null);
    }

    // достать все объекты для смены
    const facilities = [];
    facilityInstances.forEach((f) => {
      const facility = store.root.facilityStore.addFacility(null, f);
      facilities.push(facility);
    });

    // метаданные
    const startMeta = json?.startMetadata ? new ShiftStartMetaData(json.startMetadata) : null;
    const finishMeta = json?.finishMetada ? new ShiftFinishMetaData(json.finishMetada) : null;

    const shift = new ScannerWorkingShift(
      store,
      id,
      employee,
      startedAt,
      finishedAt,
      startCarPhotos,
      finishCarPhotos,
      startScannerPhotos,
      finishScannerPhotos,
      car,
      scanner,
      stationsCount,
      mileage,
      mileageExpense,
      facilityExpense,
      shiftExpense,
      totalExpenses,
      facilities,
      startMeta,
      finishMeta
    );

    if (!!startMeta) startMeta.setShift(shift);
    if (!!finishMeta) finishMeta.setShift(shift);

    return shift;
  }

  /**
   * Продолжительность смены в часах.
   */
  @computed
  get duration() {
    // Если смена еще не завершена посчитаем текущую длительность
    if (this.finishedAt == null) {
      const now = moment();
      return Math.round(moment.duration(now.diff(this.startedAt)).asHours());
    }
    return Math.round(moment.duration(this.finishedAt.diff(this.startedAt)).asHours());
  }

  @computed get isFinished() {
    return this.finishedAt !== null;
  }

  /**
   * Сегменты пути рабочей смены. Перемещения работника в смене от объекта к объекту.
   *
   * Начинаем всегда в офисе, заканчиваем всегда в офисе. Следовательно для смены
   * с одним объектом будет 2 записи. Для смены с двумя объектами - три и так далее.
   */
  @computed get pathSegments() {
    const locs = [];
    this.facilities.forEach((f, index) => {
      let from;
      let departure;
      let to;
      let arrival;

      // начинаем из офиса
      if (index === 0) {
        from = "Office";
        departure = this.startedAt;
        arrival = !!f.scanningPlan.started ? moment(f.scanningPlan.started.datetime) : null;
        to = f.name;
      } else {
        from = this.facilities[index - 1].name;
        departure = !!this.facilities[index - 1].scanningPlan.finished
          ? moment(this.facilities[index - 1].scanningPlan.finished.datetime)
          : null;
        arrival = !!f.scanningPlan.started ? moment(f.scanningPlan.started.datetime) : null;
        to = f.name;
      }

      // добавляем точки
      locs.push({
        no: index + 1,
        departure,
        from,
        arrival,
        to,
        mileage: f.scanningPlan.finished.mileage,
        contractNumber: f.contractInfo?.number,
      });

      // и путь до офиса обратно если смена закрыта
      if (this.finishMeta && index === this.facilities.length - 1) {
        locs.push({
          no: index + 2,
          departure: !!f.scanningPlan.finished ? moment(f.scanningPlan.finished.datetime) : null,
          arrival: this.finishedAt,
          from: f.name,
          to: "Office",
          mileage: this.finishMeta?.returnMileage,
          contractNumber: null,
        });
      }
    });
    return locs;
  }

  /**
   * Выполненные сканирования в смену. Договор, что, когда и сколько станций
   */
  @computed get facilityScannings() {
    const scannings = [];
    this.facilities.forEach((f, index) => {
      scannings.push({
        id: f.id,
        name: f.name,
        area: f.area,
        contractNumber: f.contractInfo?.number,
        stationsCount: f.scanningPlan.finished?.stationsCount || null,
        startedAt: !!f.scanningPlan.started ? moment(f.scanningPlan.started?.datetime) : null,
        finishedAt: !!f.scanningPlan.finished ? moment(f.scanningPlan.finished?.datetime) : null,
      });
    });
    return scannings;
  }
}

export default ScannerWorkingShift;

import { action, computed, makeObservable, observable } from "mobx";

import moment from "moment";

import { ContractStore } from "~/modules/contracts/data";

import { ClientStore } from "~/modules/clients/data";
import { InvoiceStore } from "~/modules/invoices/data";

import EmployeesStore from "./employeesStore";
import FacilityStore from "./facilityStore";
import BranchStore from "../modules/branches/data/branchStore";
import Api from "../api/Api";
import LanguageStore from "./languageStore";
import ReportStore from "./reportStore";
import ScannerReportStore from "./scannerReportStore";

import ExpenseStore from "../modules/expenses/ExpenseStore";
import CommercialOffersStore from "../modules/commercial_offers/CommercialOffersStore";
import DocumentStore from "../modules/documents/DocumentStore";

import PhotoStore from "../modules/photos/PhotoStore";

import { AuthStore } from "../modules/auth/data";
import { WorkspaceStore } from "~/workspace";

import { ScanningPlansStore } from "~/modules/scanning/data";
import { MaterialsStore } from "~/modules/materials/data";
import { EquipmentStore } from "~/modules/equipment/data";
import { WorkingShiftsStore } from "~/modules/scanners_working_shifts/data";

class RootStore {
  @observable token; // токен аутентификации

  @observable isLocal = false;
  @observable id = null;

  // @observable api = null;
  @observable views = [];
  @observable view = "login";
  @observable pending = false;

  @observable error = false;
  @observable errorTitle = null;
  @observable errorText = null;

  @observable contractStore = null;
  @observable branchStore = null;
  @observable invoiceStore = null;
  @observable facilityStore = null;
  @observable clientStore = null;
  @observable employeesStore = null;
  @observable reportStore = null;
  @observable languageStore = null;
  @observable documentStore = null;
  @observable scanningPlansStore;
  @observable materialsStore;
  @observable equipmentStore;
  @observable workingShiftsStore;

  @observable photoStore;
  @observable expenseStore;

  // --
  @observable authStore;
  @observable workspaceStore;

  constructor() {
    makeObservable(this);

    this.api = new Api(this);

    // --
    this.authStore = new AuthStore(this);
    this.workspaceStore = new WorkspaceStore(this);
    // --

    this.languageStore = new LanguageStore(this);
    this.employeesStore = new EmployeesStore(this);

    this.clientStore = new ClientStore(this);
    this.branchStore = new BranchStore(this);
    this.reportStore = new ReportStore(this);
    this.scannerReportStore = new ScannerReportStore(this);

    this.facilityStore = new FacilityStore(this);
    this.contractStore = new ContractStore(this);
    this.invoiceStore = new InvoiceStore(this);

    this.expenseStore = new ExpenseStore(this);
    this.commercialOffersStore = new CommercialOffersStore(this);

    this.documentStore = new DocumentStore(this);
    this.photoStore = new PhotoStore(this);

    this.scanningPlansStore = new ScanningPlansStore(this);
    this.materialsStore = new MaterialsStore(this);
    this.equipmentStore = new EquipmentStore(this);
    this.workingShiftsStore = new WorkingShiftsStore(this);
  }

  async getLocation() {
    this.setError();
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        this.setError("warning", "Geolocation error", "Geolocation is not supported");
        resolve({
          coords: {
            latitude: 0,
            longitude: 0,
          },
          timestamp: moment().timestamp(),
        });
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          () => {
            this.setError("error", "Geolocation error", "Geolocation is disabled");
            reject(new Error("Geolocation disabled by user"));
          }
        );
      }
    });
  }

  @action async init() {
    await this.expenseStore.init(); // TODO: здесь нужен бранч, и здесь оно должно работать. Но пока нет
    //   this.setError();
    //   this.branchStore.init();
    //   this.languageStore.init();
    //   this.setFirstView();
  }

  @action setViews(views) {
    this.views = views;
    this.setFirstView();
  }

  @action setError(error = null, title = null, text = null) {
    this.error = error;
    this.errorTitle = title;
    this.errorText = text;
  }

  // TODO: delete!
  // @action async uploadPhotos(filesArray = []) {
  //   console.warn("using deprecated method RootStore.uploadPhotos");
  //   const filePromises = [];
  //   filesArray.forEach((file) => {
  //     filePromises.push(this.api.uploadPhoto({ file }));
  //   });
  //   const resolvedPromises = await Promise.all(filePromises);
  //   return resolvedPromises.map((item) => item.id);
  // }

  @action
  async uploadDocuments(filesArray = []) {
    const filePromises = [];
    filesArray.forEach((file) => {
      filePromises.push(this.api.uploadDocument({ file }));
    });
    const resolvedPromises = await Promise.all(filePromises);
    return resolvedPromises.map((item) => item.id);
  }

  // TODO: здесь не нужно, или вообще не нужно или в DocumentStore
  @action
  async getDocumentsInfo(ids = []) {
    console.warn("rootStore.getDocumentsInfo: deprecated!");
    const promises = ids.map((id) => this.api.getDocumentInfo({ id }));
    return await Promise.all(promises);
  }

  @action
  async getPhotosInfo(ids = []) {
    const filePromises = [];
    ids.forEach((id) => {
      filePromises.push(this.api.getPhotoInfo({ id }));
    });
    return await Promise.all(filePromises);
  }

  @action
  async getDocument(id) {
    return await this.api.getDocument({ id });
  }

  @action
  async getPhoto(id) {
    return await this.api.getPhoto({ id });
  }

  @action setFirstView() {
    const view = localStorage.getItem("view");
    if (!!view && this.availableViewsSet.has(view)) {
      this.setView(view);
    } else {
      this.setView((this.availableViews.length && this.availableViews[0]) || "login");
    }
  }

  @action setView(view) {
    this.view = view;
    if (view !== "login") {
      localStorage.setItem("view", view);
    }
  }

  @action setPending(pending = false) {
    this.pending = pending;
  }

  @computed get isPending() {
    return this.pending;
  }

  @computed get currentView() {
    return this.view;
  }

  @computed get isPendingCritical() {
    return this.pending;
    // return this.languageStore.isPending;
    // this.rendererStore.isPending ||
    // this.scannerStore.isPending ||
    // this.branchStore.isPending ||
    // this.clientStore.isPending ||
    // this.facilityStore.isPending
    // this.contractStore.isPending
  }
}

export default RootStore;

import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { LoadingButton } from "@mui/lab";
import { Box, Tooltip } from "@mui/material";

import useStores from "~/hooks";
import Translator from "../../components/Translator";

/**
 * Кнопка изменения состояния контракт.
 */
const StateButton = observer(({ contract, state }) => {
  const { contractStore } = useStores();
  const status = contractStore.getStatus(state);

  const onClick = useCallback(() => {
    const change = async () => {
      contractStore.changeContractState(contract, status);
    };
    if (contract.canSwitch[status.slug]) change();
  }, [contractStore, contract, status]);

  return (
    <Tooltip
      arrow
      title={
        <>
          <Translator text="Change contract status to" />
          &nbsp;"
          <Translator text={state} />"
        </>
      }
    >
      {/* Здесь нужна обертка из-за тултипа. Без неё будет ругань в случае disabled button */}
      {/* You are providing a disabled `button` child to the Tooltip component. A disabled element 
      does not fire events. Tooltip needs to listen to the child element's events to display 
      the title. Add a simple wrapper element, such as a `span` */}
      <Box>
        <LoadingButton
          onClick={onClick}
          loading={contract.isPending}
          disabled={!contract.canSwitch[status.slug]}
          key={state.slug}
          variant="contained"
          color="info"
        >
          <Translator text={state} />
        </LoadingButton>
      </Box>
    </Tooltip>
  );
});

export default StateButton;

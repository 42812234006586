import React, { useState, useMemo, useCallback } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { DateRangePicker, Module } from "../../components";
import { Toolbar } from "@mui/material";
import SearchField from "../../components/SearchField";
import Translator from "../../components/Translator";

import ScannersWorkingShiftsGrid from "./components/ScannersWorkingShiftsGrid";
import WorkingShiftsStore from "./data/WorkingShiftsStore";

/**
 * Модуль отображения рабочих смен.
 */
const ScannersWorkingShiftsModule = observer(() => {
  const [searchBy, setSearchBy] = useState("");
  const [daterange, setDaterange] = useState([null, null]);

  const { rootStore } = useStores();

  const store = useMemo(() => {
    return new WorkingShiftsStore(rootStore);
  }, [rootStore]);

  const onSearch = useCallback((value) => {
    if (value.length > 2) {
      setSearchBy(value);
    } else {
      setSearchBy("");
    }
  }, []);

  const onAccept = useCallback((value) => {
    setDaterange(value);
  }, []);

  return (
    <Module>
      <Toolbar disableGutters={true}>
        <SearchField label={<Translator text={"Search working shifts..."} />} onSearch={onSearch} />
        <DateRangePicker onAccept={onAccept} />
      </Toolbar>
      <ScannersWorkingShiftsGrid store={store} searchBy={searchBy} daterange={daterange} />
    </Module>
  );
});

export default ScannersWorkingShiftsModule;

import React, { useMemo, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { Dialog, DialogTitle, useTheme, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { Form, Preloader, Translator, Notify } from "~/components";

/**
 * Базовый диалог с формой.
 *
 * @param {*} props
 */
const FormDialog = (props) => {
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const {
    size,
    formConfig,
    isVisible,
    closeDialog,
    title,
    notifyText,
    handleSubmit,
    isPending,
    formErrors,
    pendingText,
    errorText,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const maxWidth = useMemo(() => {
    if (isMobile) return "100vw";
    if (size === "large") return "90vw";
    return "60vw";
  }, [isMobile, size]);

  const isError = useMemo(() => {
    return !!errorText;
  }, [errorText]);

  const header = useMemo(() => {
    if (!isPending) return title;
    if (pendingText) return pendingText;
    return pendingText;
  }, [title, pendingText, isPending]);

  return (
    <>
      <Dialog open={isVisible} onClose={closeDialog} sx={{ ".MuiPaper-root": { maxWidth, borderColor: "red" } }}>
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <>
            <Box sx={{ flex: 3 }}>
              <Translator text={header} after={!!pendingText ? "..." : ""} />
            </Box>
            <Preloader isPending={isPending} size={"2rem"} sx={{ justifyContent: "flex-end" }} />
          </>
        </DialogTitle>
        {isError && (
          <Box
            sx={{
              p: 2,
              background: theme.palette.red.light,
              display: "flex",
              flexDirection: "row",
              color: theme.palette.grey["900"],
            }}
          >
            <ErrorIcon sx={{ mr: 1 }} />
            <Translator text={errorText} />
          </Box>
        )}
        <Form
          config={formConfig}
          isPending={isPending}
          errors={formErrors}
          onSubmit={handleSubmit}
          onCancel={closeDialog}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={notifyText} />
      </Notify>
    </>
  );
};

export default FormDialog;

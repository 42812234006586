import { action, computed, makeObservable, observable } from "mobx";
import User from "../models/User";

class EmployeesStore {
  @observable rootStore = null;
  @observable api = null;
  @observable employeesMap = new Map();
  @observable fetchEmployeesAbortController = null;
  @observable pending = false;

  constructor(rootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.api = this.rootStore.api;
  }

  @action async fetchEmployees(branch) {
    if (branch) {
      try {
        this.setPending(true);
        const employeesData = await this.api.getEmployeesForBranch(branch, this.rootStore.isLocal);
        this.processEmployees(employeesData);
      } catch (error) {
        console.warn(error);
      }
      this.setPending(false);
    }
  }

  @action processEmployees(employeesData) {
    this.employeesMap.clear();
    employeesData.forEach((employeeData) => {
      this.addEmployee(employeeData);
    });
  }

  @action addEmployee(data) {
    const employee = new User(data, this);
    this.employeesMap.set(`${employee.id}`, employee);
    return employee;
  }

  /**
   * Если работника нет в сторе - добавить его туда. А если есть - оставить как есть.
   *
   * @param {object} data
   * @returns Employee instance
   */
  @action ensureEmployee(data) {
    const { id } = data;
    if (!this.employeesMap.has(`${id}`)) {
      return this.addEmployee(data);
    }
    return this.employeesMap.get(`${id}`);
  }

  getEmployeeById(id) {
    return this.employeesMap.get(`${id}`);
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed get employeesArray() {
    return Array.from(this.employeesMap.values());
  }

  @computed get scannersArray() {
    const scanners = [];
    this.employeesMap.forEach((employee) => {
      if (employee.isScanner) {
        scanners.push(employee);
      }
    });
    return scanners;
  }

  @computed
  get renderersArray() {
    const renderers = [];
    this.employeesMap.forEach((employee) => {
      if (employee.isRenderer) {
        renderers.push(employee);
      }
    });
    return renderers;
  }

  @computed
  get managersArray() {
    const managers = [];
    this.employeesMap.forEach((employee) => {
      if (employee.isManager) {
        managers.push(employee);
      }
    });
    return managers;
  }

  @computed
  get modellingManagersArray() {
    const managers = [];
    this.employeesMap.forEach((employee) => {
      if (employee.isModelingManager) {
        managers.push(employee);
      }
    });
    return managers;
  }

  @computed
  get employeeIdsArray() {
    return Array.from(this.employeesMap.keys());
  }
}

export default EmployeesStore;

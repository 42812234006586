import React from "react";
import { observer } from "mobx-react";

import { DataGridPremium as MuiGrid } from "@mui/x-data-grid-premium";

/**
 * Общая таблица для использования в системе.
 */

const DataGrid = observer(
  ({
    columns,
    rows,
    rowCount,
    loading,
    pagination,
    paginationMode,
    paginationModel,
    onPaginationModelChange,
    ...props
  }) => {
    return (
      <MuiGrid
        disableColumnMenu // TODO: убрать когда всегде уберу x-data-grid. Из-за двух гридов не работает
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        stickyHeader={true}
        loading={loading}
        sx={{
          "& .MuiDataGrid-cell": {
            // чтобы был отступ в ячейке при использовании динамической её высоты
            padding: 1,
            // чтобы текст был по вернхнему краю
            alignItems: "baseline",
          },
        }}
        // autoPageSize={true}
        pageSizeOptions={[10, 20, 25, 50, 100]}
        pagination={pagination}
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        autoHeight={false}
        {...props}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 52}
      />
    );
  }
);

export default DataGrid;

import { computed, action, observable, makeObservable, runInAction } from "mobx";

import API from "../api";

import ScannerWorkingShift from "./models/ScannerWorkingShift";
import Shift from "~/models/Shift";
/**
 * Стор рабочих смен сканировщиков.
 */
class WorkingShiftsStore {
  // Идет ли загрузка данных
  @observable isPending = false;

  @observable shiftMap = new Map();
  @observable totalCount = 0;

  @observable shift;
  @observable pendingOperations = 0;
  @observable pendingText = "";

  constructor(rootStore) {
    makeObservable(this);

    this.root = rootStore;
    this.api = new API(rootStore);
    this.oldApi = rootStore.api;
  }

  @action setPending(value) {
    this.isPending = !!value;
  }

  /**
   * Запросить открытую смену для роли.
   */
  @action async fetchOpenShift(branch) {
    const ROLE = "scanner";

    this.setPending(true);
    const employeeId = this.root.authStore.userId;
    const shiftsArray = await this.oldApi.getShiftForRole(branch.id, employeeId, ROLE);
    if (shiftsArray.length > 0) {
      runInAction(() => {
        this.shift = new Shift(shiftsArray[0], this, branch);
      });
    }
    this.setPending(false);
  }

  /**
   * Открыть рабочую смену сканировщика.
   */
  @action async openWorkingShift(branch, data) {
    const ROLE = "scanner";

    // TODO: (ai) убрал все ключи без значений
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    runInAction(() => { this.setPending(true); this.pendingText = "Uploading photos"; });
    if (data.scannerPhoto && data.scannerPhoto.length) {
      const scannerPhotoIdsArray = await this.root.photoStore.uploadPhotos(data.scannerPhoto);
      data.scannerPhotoIds = scannerPhotoIdsArray;
    }
    if (data.carPhoto && data.carPhoto.length) {
      const carPhotoIdsArray = await this.root.photoStore.uploadPhotos(data.carPhoto);
      data.carPhotoIds = carPhotoIdsArray;
    }

    runInAction(() => { this.pendingText = "Opening shift"; });
    const result = await this.oldApi.startShift(branch.id, ROLE, data);
    if (result && result.id) {
      runInAction(() => {
        this.shift = new Shift(result, this, branch);
        this.setPending(false);
        this.pendingText = "";
      });
      return [this.shift, null];
    } else {
      // А это считаем ошибкой
      runInAction(() => {
        this.setPending(false);
        this.pendingText = "";
      });
      return [this.shift, result];
    }
  }

  /**
   * Закрыть рабочую смену сканировщика.
   */
  @action async closeWorkingShift(shift, data) {
    // TODO: (ai) убрал все ключи без значений
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    runInAction(() => { this.setPending(true); this.pendingText = "Uploading photos"; });

    if (data.scannerPhoto && data.scannerPhoto.length) {
      const scannerPhotoIds = await this.root.photoStore.uploadPhotos(data.scannerPhoto);
      data.scannerPhotoIds = scannerPhotoIds;
    }

    if (data.carPhoto && data.carPhoto.length) {
      const carPhotoIds = await this.root.photoStore.uploadPhotos(data.carPhoto);
      data.carPhotoIds = carPhotoIds;
    }

    runInAction(() => { this.pendingText = "Closing shift"; });
    const result = await this.oldApi.finishShift(shift.id, data);
    if (result && result.id) {
      runInAction(() => {
        this.shift = null; // не текущей смены
        this.setPending(false);
        this.pendingText = "";
      });
      return [true, null];
    } else {
      runInAction(() => {
        this.setPending(false);
        this.pendingText = "";
      });
      return [false, result];
    }
  }

  /**
   * Fetches scanner's working shifts from backend.
   *
   * @param {integer} branchId
   * @returns {array} fetched scanner's working shifts
   */
  @action async fetchShifts(branchId, pagination, searchBy, daterange) {
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };

    this.setPending(true); // TODO: вынести этот паттерн в декоратор?

    const result = await this.api.getBranchWorkingShifts(
      branchId,
      backendPagination,
      searchBy,
      daterange || [null, null]
    );
    const { shifts, totalCount } = result;

    // TODO: Сделать моделей, положить их в стор.
    const resultShifts = [];
    for (let i = 0; i < shifts.length; i++) {
      const json = shifts[i];
      const shift = await ScannerWorkingShift.create(this, json);
      resultShifts.push(shift);
    }

    runInAction(() => {
      this.shiftMap.clear();
      this.totalCount = totalCount;
      resultShifts.forEach((s) => {
        this.shiftMap.set(`${s.id}`, s);
      });
    });

    this.setPending(false);
    return resultShifts;
  }

  /**
   * Вернуть все известные смены из стора.
   */
  @computed get shifts() {
    const array = [];
    this.shiftMap.forEach((shift) => {
      array.push(shift);
    });
    return array;
  }

  getShift(id) {
    return this.shiftMap.get(`${id}`);
  }
}

export default WorkingShiftsStore;

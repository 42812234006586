import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box, Dialog, Fab } from "@mui/material";

import useStores from "~/hooks";
import GantSchedule from "../modules/rendering/GantSchedule";
import { Add } from "@mui/icons-material";
import Form from "../components/Form";
import Preloader from "../components/Preloader";
import ModellingCards from "../modules/rendering/ModellingCards";

const ModellerSchedule = observer(() => {
  const { rootStore, branchStore, facilityStore } = useStores();
  const { addModellingFormConfig, isPending } = facilityStore;
  const { branch } = branchStore;
  const { id } = rootStore;

  const [modalVisible, setModalVisible] = useState(false);
  const [isFormPending, setIsFormPending] = useState(false);

  useEffect(() => {
    if (!branch) return;
    facilityStore.fetchRenderings(branch.id);
  }, [branch, facilityStore]);

  const onOpenModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const onCloseModal = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setModalVisible(false);
    },
    [setModalVisible]
  );

  const onSubmit = useCallback(
    async (data) => {
      setIsFormPending(true);
      await facilityStore.addNewModellingPlan(data);
      setIsFormPending(false);
      onCloseModal();
    },
    [onCloseModal]
  );

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        flex: 1,
        padding: "0.5rem",
        pt: "1rem",
        overflow: "hidden",
      }}
    >
      {!isPending && <GantSchedule role={"modeller"} managerId={id} />}
      {!isPending && <ModellingCards />}
      <Preloader isPending={isPending} />
      <Fab
        onClick={onOpenModal}
        key="fab"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
        }}
        color="primary"
        aria-label="AddPlan"
      >
        <Add />
      </Fab>
      <Dialog open={modalVisible} onClose={onCloseModal}>
        <Form
          isPending={isFormPending}
          config={addModellingFormConfig}
          onSubmit={onSubmit}
          onCancel={onCloseModal}
          initialValues={{}}
        />
      </Dialog>
    </Box>
  );
});

export default ModellerSchedule;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";

import Translator from "../components/Translator";
import Field from "./Field";
// import Preloader from "./Preloader";
import { useTheme } from "@emotion/react";

const Form = observer((props) => {
  const { initialValues, noCancel, errors, alert, isPending, sx } = props;
  const { payload, config, variant, additionalContent, onSubmit, onCancel } = props;
  const { submitText, cancelText, formTitle, formText, fields, content } = config;

  const [values, setValues] = useState({});
  const [fileValues, setFileValues] = useState({});
  const [externalErrors, setExternalErrors] = useState(errors || {});

  useEffect(() => {
    setExternalErrors(errors || {});
  }, [errors]);

  const onCancelForm = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onCancel(payload);
    },
    [payload, onCancel]
  );

  const onSubmitForm = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const data = new FormData(e.currentTarget);
      onSubmit({ ...Object.fromEntries(data.entries()), ...fileValues }, payload);
    },
    [payload, onSubmit, fileValues]
  );

  const setFieldValue = useCallback((name, value) => {
    setValues((prevValues) => {
      return { ...prevValues, [name]: value };
    });
    setExternalErrors((prevExternalErrors) => {
      return { ...prevExternalErrors, [name]: null };
    });
  }, []);

  const setFileValue = useCallback((name, value) => {
    setFileValues((prevValues) => {
      return { ...prevValues, [name]: value };
    });
    setExternalErrors((prevExternalErrors) => {
      return { ...prevExternalErrors, [name]: null };
    });
  }, []);

  const fieldsRender = useMemo(() => {
    const fieldsArray = [];
    fields.forEach((field) => {
      fieldsArray.push(
        <Field
          key={field.name}
          externalValidationError={externalErrors[field.name]}
          values={values}
          setFieldValue={setFieldValue}
          setFileValue={setFileValue}
          payload={initialValues}
          config={field}
          isDisabled={isPending}
        />
      );
    });

    return fieldsArray;
  }, [isPending, values, fields, initialValues, externalErrors, setFieldValue, setFileValue]);

  const additionalStyle = useMemo(() => {
    let style = {};
    if (variant === "row") {
      style = {
        display: "flex",
        flexDirection: "row",
      };
    }
    return style;
  }, [variant]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="form"
      sx={{
        minWidth: "15rem",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        ...additionalStyle,
      }}
      onSubmit={onSubmitForm}
    >
      {formTitle && (
        <DialogTitle>
          <Translator text={formTitle} />
        </DialogTitle>
      )}
      <DialogContent sx={{ ...sx, ...additionalStyle }} dividers={true}>
        {formText && (
          <DialogContentText>
            <Translator text={formText} />
          </DialogContentText>
        )}
        {additionalContent}
        <Box sx={{ mt: 1.5 }}>{content}</Box>
        {fieldsRender}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", flexDirection: mobile ? "column" : "row" }}>
          {alert}
          {!mobile && <Box sx={{ minWidth: "0.25rem", flex: 1 }} />}
          <Box sx={{ display: "flex", mt: mobile ? "0.25rem" : 0, flexDirection: "row" }}>
            {!noCancel && (
              <Button color="error" variant="outlined" onClick={onCancelForm} disabled={isPending}>
                <Translator text={cancelText || "Cancel"} />
              </Button>
            )}
            <Button sx={{ ml: "0.25rem" }} color="success" variant="contained" type="submit" disabled={isPending}>
              <Translator text={submitText || "Submit"} />
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Box>
  );
});

export default Form;

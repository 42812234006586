import { observer } from "mobx-react";
import { Box, Typography as T } from "@mui/material";

import { ContractButton } from "~/modules/contracts/components";

const FacilityInfoCard = observer(({ facility }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <T variant="h6" sx={{ color: "#1177d3" }}>
          {facility.label}
        </T>
        <ContractButton sx={{ marginLeft: "auto" }} number={facility.contractNumber} id={facility.contractId} />
      </Box>
      <T variant="body1">{facility.description}</T>
      <T variant="body2">{facility.contract?.number}</T>
    </Box>
  );
});

export default FacilityInfoCard;

import { action, computed, makeObservable, observable, runInAction } from "mobx";
import moment from "moment";
import Facility from "../models/Facility";

class FacilityStore {
  @observable rootStore = null;
  @observable api = null;
  @observable facilitiesMap = new Map();
  @observable facilitiesByUserId = new Map();
  @observable pendingFacilitiesMap = new Map();
  @observable pending = false;
  @observable pendingScanningPlans = false;
  @observable pendingRenderingPlans = false;
  @observable kindsArray = ["building", "house", "flat", "premises", "townhouse", "other"];

  // new from @ai
  @observable contract = null;

  constructor(rootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.api = this.rootStore.api;
  }

  // if (data.scanningPlan) {
  //   this.rootStore.contractStore.addService({
  //     ...data.scanningPlan,
  //     kind: "scanning",
  //   });
  // }

  // if (data.modellingPlans && data.modellingPlans.length) {
  //   data.modellingPlans.forEach((plan) => {
  //     this.rootStore.contractStore.addService({
  //       ...plan,
  //       kind: "modelling",
  //     });
  //   });
  // }
  @action addFacility(contract, data) {
    const facility = new Facility(this, contract, data);
    this.facilitiesMap.set(facility.id, facility);
    return facility;
  }

  @action getFacilityById(id) {
    return this.facilitiesMap.get(`${id}`);
  }

  @action
  clearFacilities() {
    this.pendingFacilitiesMap.clear();
    this.facilitiesMap.clear();
  }

  @action
  async getFacilityByIdAsync(id) {
    if (this.pendingFacilitiesMap.get(`${id}`)) {
      return this.pendingFacilitiesMap.get(`${id}`);
    }
    const promise = this.fetchFacilityByIdAsync(id);
    this.pendingFacilitiesMap.set(`${id}`, promise);
    return promise;
  }

  @action
  async fetchFacilityByIdAsync(id) {
    const facilityData = await this.api.getFacilityById(id);
    const facility = this.addFacility(null, facilityData);
    return facility;
  }


  @action
  async getMissingContracts(facilities) {
    const contractIds = [];
    facilities.forEach((facility) => {
      const { contractId } = facility;
      const contract = this.rootStore.contractStore.getContractById(contractId);
      if (!contract) {
        contractIds.push(contractId);
      }
    });
    if (contractIds.length) {
      // await this.rootStore.contractStore.getContractsAsync(this.rootStore.branchStore.currentBranchId, contractIds);
    }
  }

  /**
   * Запросить данные по
   * 
   * @param {*} branchId 
   */
  @action async fetchRenderings(branchId) {
    this.setPending(true);
    this.facilitiesMap.clear();
    this.rootStore.contractStore.clearServisesByType("modelling");

    const facilities = await this.api.getRenderFacilities({branchId});
    if (facilities) {
      // await this.getMissingContracts(facilities);
      facilities.forEach((facilityData) => this.addFacility(null, facilityData));
    }
    this.setPending(false);
  }

  /**
   * Вызывается только из Schedule.jsx
   * 
   * @param {*} branchId 
   * @param {*} from 
   * @param {*} to 
   */
  @action async fetchScanningPlans(branchId, from, to) {
    this.setPendingScanningPlans(true);
    const facilities = await this.api.getScanningPlans({
      withFinished: true,
      withResult: true,
      branchId,
      from,
      to,
    });
    if (facilities) {
      this.processFacilitiesForPlans(facilities);
    }
    this.setPendingScanningPlans(false);
  }

  @action
  async fetchRenderingPlans(branchId, from, to) {
    this.setPendingRenderingPlans(true);
    this.rootStore.contractStore.clearServisesByType("scanning");
    const plans = await this.api.getRenderingPlans({
      withFinished: true,
      withResult: true,
      branchId,
      from,
      to,
    });
    if (plans) {
      await this.processModellingPlans(plans);
    }
    this.setPendingRenderingPlans(false);
  }

  @action
  async processModellingPlans(plans) {
    const promises = [];
    plans.forEach((plan) => {
      promises.push(this.processModellingPlan(plan));
    });
    await Promise.all(promises);
  }

  @action
  async processModellingPlanArray(data) {
    if (data.modellingPlan && data.modellingPlan.length) {
      data.modellingPlan.forEach((plan) => {
        this.processModellingPlan(plan);
      });
    }
  }

  @action async processModellingPlan(plan) {
    // const facility = this.getFacilityById(plan.facilityId);
    // if (!facility) {
    await this.fetchFacilityByIdAsync(plan.facilityId);
    // }
    // if (plan) {
    //   this.rootStore.contractStore.addService({
    //     ...plan,
    //     kind: "modelling",
    //   });
    // }
  }

  @action processFacilitiesForPlans(facilities) {
    this.facilitiesByUserId.clear();
    facilities.forEach((facilityData) => {
      // TODO: здесь проблема в отсутсвии контракта в аргументах addFacility
      // считаем что контракт есть в сторе
      const contract = this.rootStore.contractStore.getContractById(facilityData.contractId);
      const facility = this.addFacility(contract, facilityData);
      if (facility && facility.scanningPlan) {
        const item = this.facilitiesByUserId.get(`${facility.scanningPlan.employee.id}`);
        if (item) {
          item.push(facility);
        } else {
          this.facilitiesByUserId.set(`${facility.scanningPlan.employee.id}`, [facility]);
        }
      }
    });
  }

  @action
  setPendingScanningPlans(pending = false) {
    this.pendingScanningPlans = pending;
  }
  @action
  setPendingRenderingPlans(pending = false) {
    this.pendingRenderingPlans = pending;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  editModellingPlan(plan) {
    this.rootStore.contractStore.updateServise(plan);
  }

  @action
  async addNewModellingPlan(data) {
    const newPlanData = await this.api.setNewModellingPlan({
      ...data,
      start: moment(data.start, "L").format("YYYY-MM-DD"),
      finish: moment(data.finish, "L").format("YYYY-MM-DD"),
    });
    this.processModellingPlan(newPlanData);
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isPendingScanningPlans() {
    return this.pendingScanningPlans;
  }

  @computed
  get isPendingRenderingPlans() {
    return this.pendingRenderingPlans;
  }

  @computed
  get kindsArrayForSelect() {
    return this.kindsArray.map((kind) => {
      return { label: kind, value: kind };
    });
  }

  @computed
  get hasStartedScanning() {
    let hasStarted = false;
    this.facilitiesArray.forEach((facility) => {
      if (facility.scanPlan && facility.scanPlan.isStarted && !facility.scanPlan.isFinished) {
        hasStarted = true;
      }
    });
    return hasStarted;
  }

  @computed
  get facilitiesToPlanModelling() {
    const facilities = [];
    this.facilitiesArray.forEach((facility) => {
      if (facility && (!facility.modellingPlans || facility.modellingPlans.length === 0)) {
        facilities.push(facility);
      }
    });

    return facilities;
  }

  // TODO: ВЫПИЛИТЬ ФОРМУ ИЗ СТОРА ДАННЫХ
  @computed
  get addModellingFormConfig() {
    const fields = [];

    fields.push({
      name: "facilityId",
      fakeName: "facility",
      title: "Facility",
      type: "select",
      canCreate: false,
      isRequired: true,
      isReadOnly: false,
      validate: true,
      loading: this.isPending || this.isPendingRenderingPlans,
      options: this.facilitiesToPlanModelling,
      initialValue: "payload",
    });

    fields.push({
      name: "modellerId",
      fakeName: "modeller",
      title: "Modeller",
      type: "select",
      canCreate: false,
      isRequired: true,
      isReadOnly: false,
      validate: true,
      loading: this.rootStore.employeesStore.isPending,
      options: this.rootStore.employeesStore.renderersArray,
    });

    fields.push({
      name: "start",
      title: "Start",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: moment().toDate(),
    });

    fields.push({
      name: "finish",
      title: "finish",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: moment().toDate(),
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Add modelling plan",
      formText: "To create new modelling plan fill in the form below",
      fields,
    };
  }

  @computed
  get facilitiesArray() {
    return Array.from(this.facilitiesMap.values());
  }

  @computed
  get facilityIdsArray() {
    return Array.from(this.facilitiesMap.keys());
  }

  //------ Renovated methods.

  /**
   * Creates new Facility.
   *
   * @param {Contract} contract Контракт к которому мы добавляем объект.
   * @param {object} facilityData Данные объекта.
   */
  @action async createFacility(contract, facilityData) {
    const payload = { ...facilityData, description: facilityData.description || "" };
    const created = await this.api.addFacility(payload);
    let facility;
    runInAction(() => {
      const facility = this.addFacility(contract, created);
      contract.addFacility(facility);
    });
    return facility;
  }

  /**
   * Creates new Scanning Plan for Facility.
   *
   * @param {}
   * @param {}
   */
  @action async createFacilityScanningPlan({ facilityId, finish, scannerId, start }) {
    // pass
    const planData = await this.api.addScanPlan({
      facilityId,
      employeeId: scannerId,
      start: moment(start).utcOffset(0, true).toISOString(),
      finish: moment(finish).utcOffset(0, true).toISOString(),
    });
    const facility = this.facilitiesMap.get(`${planData.facilityId}`);
    const scanningEmployee = this.rootStore.employeesStore.getEmployeeById(planData.employeeId);
    runInAction(() => {
      facility.scanningPlan = this.rootStore.scanningPlansStore.addPlan(planData, this, scanningEmployee);
    });
    return facility.scanningPlan;
  }
}

export default FacilityStore;

import { useMemo } from "react";
import { observer } from "mobx-react";
import { DataGrid } from "@mui/x-data-grid";
import { Translator as T } from "~/components";

import useStores from "~/hooks";

/**
 * Таблица перемещений внутри смены.
 */
const ShiftScanningsGrid = observer(({ shift }) => {
  const { languageStore: ls } = useStores();

  const getRowId = (row) => row.id;

  const columns = useMemo(
    () => [
      { field: "id", width: 50, headerName: ls.translate({ text: "ID" }) },
      {
        field: "contractNumber",
        headerName: ls.translate({ text: "Contract number" }),
        width: 150,
        type: "string",
        valueGetter: ({ row }) => {
          return row.contractNumber;
        },
        renderCell: ({ row }) => <T text={row.contractNumber} />,
      },
      { field: "name", minWidth: 150, flex: 1, headerName: ls.translate({ text: "Name" }) },
      { field: "area", width: 100, headerName: ls.translate({ text: "Area" }) },
      {
        field: "startedAt",
        headerName: ls.translate({ text: "Started at" }),
        width: 150,
        type: "datetime",
        valueGetter: ({ row }) => row.startedAt,
        renderCell: ({ row }) => <T datetime={row.startedAt} variant="compact" />,
      },
      {
        field: "finishedAt",
        headerName: ls.translate({ text: "Finished at" }),
        width: 150,
        type: "datetime",
        valueGetter: ({ row }) => row.finishedAt,
        renderCell: ({ row }) => <T datetime={row.finishedAt} variant="compact" />,
      },
      {
        field: "stationsCount",
        width: 150,
        headerName: ls.translate({ text: "Stations count" }),
        type: "number",
      },
    ],
    [ls]
  );

  return (
    <DataGrid
      sx={{ mt: 2, mb: 5 }}
      getRowId={getRowId}
      columns={columns}
      rows={shift.facilityScannings}
      autoHeight
      hideFooter
    />
  );
});

export default ShiftScanningsGrid;

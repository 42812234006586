import React from "react";
import RootStore from "../stores/rootStore";

const root = new RootStore();

const context = React.createContext({
  rootStore: root,
  authStore: root.authStore,
  workspaceStore: root.workspaceStore,
  languageStore: root.languageStore,
  branchStore: root.branchStore,
  contractStore: root.contractStore,
  facilityStore: root.facilityStore,
  invoiceStore: root.invoiceStore,
  clientStore: root.clientStore,
  employeesStore: root.employeesStore,
  reportStore: root.reportStore,
  scannerReportStore: root.scannerReportStore,
  expenseStore: root.expenseStore,
  commercialOffersStore: root.commercialOffersStore,
  photoStore: root.photoStore,
  scanningPlansStore: root.scanningPlansStore,
  materialsStore: root.materialsStore,
  equipmentStore: root.equipmentStore,
  workingShiftsStore: root.workingShiftsStore,
});

export default context;
export { context };

import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { CssBaseline, Box, Card, CardHeader } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { LoginForm } from "../components";

/**
 * Страница входа в приложение.
 */
const LoginPage = observer(({ store, theme, renderError }) => {
  // Обработать логин, useCallback нужен чтобы стор успел создаться
  const { isPending } = store;
  const handleLogin = useCallback((data) => store.login(data), [store]);

  const config = {
    submitText: "Login",
    fields: [
      {
        name: "login",
        title: "Login",
        type: "text",
        viewConfig: "input",
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "password",
        title: "Password",
        type: "password",
        isRequired: true,
        isReadOnly: false,
        validate: false,
      },
    ],
  };

  // TODO: переделать на FormDialog
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <CssBaseline />
        {renderError}
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            p: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card>
            <CardHeader sx={{ backgroundColor: "#0e77d3", color: "#fff" }} title={"ScanM2 ERP"} />
            {/* <AppBar
              sx={{
                position: "relative",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "100%",
              }}
              enableColorOnDark={true}
            > */}
            {/* <Toolbar> */}
            {/* <ToolbarLogo /> */}
            {/* </Toolbar> */}
            {/* </AppBar> */}
            {/* <CardContent> */}
            <LoginForm handleLogin={handleLogin} isPending={isPending} />
            {/* </CardContent> */}
          </Card>
        </Box>
      </Box>
    </ThemeProvider>
  );
});

export default LoginPage;

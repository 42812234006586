import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker, LocalizationProvider, MobileDatePicker, TimePicker } from "@mui/lab";

import Translator from "../components/Translator";
import Schedule from "../modules/contracts/Schedule";
import useStores from "~/hooks";
import { Add, CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import FileInput from "./FileInput";
import Decimal from "decimal.js-light";

/* eslint-disable complexity */

// TODO: (ai): уменьшить цикломатическую сложность
const Field = observer(
  ({ isDisabled, config, payload, values, setFieldValue, setFileValue: setFormFileValue, externalValidationError }) => {
    const {
      isPending,
      isReadOnly,
      name,
      isRequired,
      multiline,
      title,
      type,
      id,
      accept,
      pattern,
      inputMode,
      min,
      max,
      maxDate,
      minDate,
      options,
      disablePast,
      disableFuture,
      canCreate,
      withCreate,
      step,
      loading,
      initialValue,
      fakeName,
      startName,
      finishName,
      role,
      employeeIdName,
      validate,
      groupBy,
      endAdornment,
      startAdornment,
      countFunction,
      revealFunction,
      multiple,
      fileType,
      withEmptyValue,
      helpText,
    } = config;

    const { languageStore } = useStores();
    const { currentLangId } = languageStore;

    const realOptions = useMemo(() => {
      return withCreate ? [{ label: `New ${title}`, value: "create new" }, ...options] : options;
    }, [options, withCreate, title]);

    const fieldInitialValue = useMemo(() => {
      if (initialValue === "payload") {
        if (type === "boolean") {
          return payload[name] === "true" || payload[name] === true;
        }
        if (type === "autocomplete") {
          return realOptions.find((option) => option.value === payload[name]);
        }
        return payload[name];
      } else {
        if (type === "boolean") {
          return initialValue === "true" || initialValue === true;
        }
        if (type === "radio") {
          return options && options[0] && options[0].value;
        }
        if (type === "autocomplete") {
          return realOptions.find((option) => option.value === initialValue);
        }
        if (type === "hidden") {
          return initialValue;
        }
        return initialValue;
      }
    }, [initialValue, payload, name, options, realOptions, type]);

    const [isChanged, setIsChanged] = useState(false);
    const [value, setValue] = useState(fieldInitialValue || "");
    const [start, setStart] = useState("");
    const [finish, setFinish] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [realValue, setRealValue] = useState((initialValue === "payload" ? payload[name] : initialValue) || "");
    const [fileValue, setFileValue] = useState("");
    const [tempValue, setTempValue] = useState("");
    const [helperText, setHelperText] = useState(helpText);

    // хак чтобы сделать рендер после загрузки данных. Например чтобы поставить номер КП после его
    // получения от сервера. Без этого useEffect рендера не будет и значения пользователь не увидит.
    useEffect(() => {
      if (isPending) return;
      setValue(fieldInitialValue);
    }, [isPending, fieldInitialValue, setValue]);

    useEffect(() => {
      if (values && values[name] !== fieldInitialValue && type === "hidden" && setFieldValue && fieldInitialValue) {
        setFieldValue(name, fieldInitialValue);
      }
      if (type === "file" && initialValue && values[name] !== initialValue && initialValue.length) {
        setFileValue(initialValue);
        setFormFileValue(name, initialValue);
      }
    }, [initialValue, fieldInitialValue, setFieldValue, values, setFormFileValue, name, type]);

    useEffect(() => {
      const changedValue = realValue || tempValue || value;
      setFieldValue && setFieldValue(name, changedValue);
    }, [value, realValue, tempValue, name, setFieldValue]);

    const setFieldFileValue = useCallback(
      (value) => {
        setFormFileValue(name, value);
        setFileValue(value);
      },
      [setFormFileValue, setFileValue, name]
    );

    const onChange = useCallback(
      (e, newValue) => {
        if (newValue && type !== "select") {
          setValue(newValue);
          setRealValue(newValue.value);
        } else {
          const numberValue = Number(e.target.value);
          if (type === "number" && !Number.isNaN(numberValue) && e.target.value !== "") {
            if (step) {
              setValue(numberValue);
            } else {
              setValue(numberValue.toString());
            }
          } else {
            setValue(e.target.value);
          }
          setRealValue(undefined);
        }
      },
      [setRealValue, setValue, type, step]
    );

    useEffect(() => {
      if (countFunction) {
        const newValue = countFunction(values);
        setValue(newValue);
      }
    }, [values, countFunction]);

    const onChangeBool = useCallback(() => {
      setValue(!value);
    }, [value, setValue]);

    const onChangeTemp = useCallback(
      (e, newValue) => {
        setTempValue(newValue || "");
      },
      [setTempValue]
    );

    const onBlurInput = useCallback(() => {
      setIsChanged(true);
    }, [setIsChanged]);

    const error = useMemo(() => {
      if (externalValidationError) {
        setHelperText(externalValidationError);
        return true;
      }
      if (!validate || !isChanged) {
        return false;
      }
      if (
        isRequired &&
        ((canCreate && (tempValue === undefined || tempValue === null || tempValue === "")) ||
          (!canCreate &&
            ((type !== "file" && (value === undefined || value === null || value === "")) ||
              (type === "file" &&
                (fileValue === undefined || fileValue === null || (fileValue && fileValue.length < 1))) ||
              (type === "boolean" && value !== "true") ||
              (type === "number" && (value === undefined || value === null || value === "")))))
      ) {
        setHelperText("This field is required!");
        return true;
      }
      // if (type === "date" && !value.isValid()) {
      if (type === "date" && !value) {
        setHelperText("Invalid date");
        return true;
      }
      if (type === "number") {
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
          setHelperText("This field should be a number");
          return true;
        }
        if (numberValue < min) {
          setHelperText(`Min value is ${min}`);
          return true;
        }
        if (numberValue > max) {
          setHelperText(`Max value is ${max}`);
          return true;
        }
        const decimalValue = new Decimal(numberValue);
        const decimalStep = new Decimal(step);
        if (!decimalValue.dividedToIntegerBy(decimalStep).equals(decimalValue.dividedBy(decimalStep))) {
          setHelperText(`Value must be multiple of ${step}`);
          return true;
        }
      }
      if (type === "file") {
        const numberValue = (fileValue && fileValue.length) || 0;
        if (numberValue < min) {
          setHelperText(`Min quantity is ${min}`);
          return true;
        }
        if (numberValue > max) {
          setHelperText(`Max quantity is ${max}`);
          return true;
        }
      }
      if (pattern) {
        const regexp = new RegExp(pattern);
        if (!regexp.test(value)) {
          setHelperText(`This field should be properly-formatted`);
          return true;
        }
      }
      setHelperText(null);
    }, [
      isRequired,
      type,
      pattern,
      min,
      max,
      value,
      fileValue,
      tempValue,
      isChanged,
      canCreate,
      step,
      validate,
      externalValidationError,
    ]);

    const fileQuantity = useMemo(() => {
      return (fileValue && fileValue.length) || 0;
    }, [fileValue]);

    const hiddenStyle = useMemo(() => {
      return {
        opacity: 0,
        width: 0,
        height: 0,
        overflow: "hidden",
      };
    }, []);

    const isRevealed = useMemo(() => {
      return !revealFunction || revealFunction(values);
    }, [revealFunction, values]);

    if (!isRevealed) {
      return null;
    }

    if (type === "autocomplete") {
      const realField = useMemo(() => {
        return <TextField sx={hiddenStyle} name={name} id={`real-${name}`} value={realValue} />;
      }, [realValue, name, hiddenStyle]);

      const getOptionLabel = useCallback(
        (option) => {
          return languageStore.getText(option.label, currentLangId);
        },
        [languageStore, currentLangId]
      );

      return (
        <FormControl margin="dense" fullWidth>
          <Autocomplete
            options={realOptions}
            id={`select-${fakeName}`}
            loading={loading}
            onChange={onChange}
            onClose={onBlurInput}
            groupBy={groupBy ? (option) => option[groupBy] : undefined}
            inputValue={tempValue}
            onInputChange={onChangeTemp}
            disabled={isDisabled}
            inputProps={{ readOnly: isReadOnly }}
            value={value || null}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBoxOutlined />} checked={selected} />
                {option.value === "create new" && <Add color="success" style={{ marginRight: "0.25rem" }} />}
                <Typography
                  sx={{
                    color: (theme) => {
                      return option.value === "create new" ? theme.palette.success.main : undefined;
                    },
                  }}
                >
                  <Translator text={option.label} />
                </Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name={fakeName}
                id={name}
                required={isRequired}
                error={error}
                helperText={<Translator text={helperText} />}
                label={<Translator text={title} />}
              />
            )}
          />
          {realField}
        </FormControl>
      );
    }

    if (type === "select") {
      // Если опция единственная и обязательная - выбрать её по умолчанию
      let selectValue;
      if (realOptions.length === 1 && isRequired) {
        selectValue = realOptions[0].id || realOptions[0].value;
      } else {
        selectValue = value || "";
      }

      const options = useMemo(() => {
        if (!realOptions || !realOptions.length) {
          return [];
        }
        const options = realOptions.map((item) => {
          // чтобы не требовать наличие value у всех моделей при испльзовании их
          // в качестве опций посмотрим на value или id сразу
          const itemValue = item.value || item.id;
          return (
            <MenuItem key={itemValue} value={itemValue}>
              <Checkbox
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBoxOutlined />}
                checked={itemValue === selectValue}
              />
              <Translator text={item.label} />
            </MenuItem>
          );
        });
        if (withEmptyValue) {
          options.push(
            <MenuItem key={"empty"} value={""}>
              <Translator text={"No value"} />
            </MenuItem>
          );
        }
        return options;
      }, [realOptions, withEmptyValue, selectValue]);

      return (
        <FormControl margin="dense" fullWidth>
          <InputLabel id={name + "-label"}>
            <Translator text={title} />
          </InputLabel>
          <Select
            {...config}
            id={id || name}
            labelid={id || name}
            name={name}
            label={<Translator text={title} />}
            value={selectValue}
            onChange={onChange}
            inputProps={{
              readOnly: isReadOnly,
              required: isRequired,
            }}
            disabled={isDisabled}
            // чтобы не прыгало при простановке значения и всегда было одной высоты
            sx={{ p: 0, "& .MuiSelect-select": { m: 0, p: !!value ? 1 : 2 } }}
          >
            {options}
          </Select>
        </FormControl>
      );
    }

    if (type === "radio") {
      const optionsRender = useMemo(() => {
        if (!realOptions || !realOptions.length) {
          return [];
        }
        return realOptions.map((option) => {
          return (
            <FormControlLabel
              value={option.value}
              key={option.value}
              control={<Radio />}
              label={<Translator text={option.label} />}
            />
          );
        });
      }, [realOptions]);

      return (
        <FormControl>
          <FormLabel sx={{ fontSize: "0.75rem" }} id={id || name}>
            <Translator text={title} />
          </FormLabel>
          <RadioGroup aria-labelledby={id || name} name={name} defaultValue={fieldInitialValue}>
            {optionsRender}
          </RadioGroup>
        </FormControl>
      );
    }

    if (type === "boolean") {
      return (
        <FormControl margin="dense" fullWidth>
          <ListItem>
            <ListItemText id="switch-list-label-boolean" primary={<Translator text={title} />} />
            <Switch
              edge="end"
              onChange={onChangeBool}
              checked={value === "true" || value === true}
              inputProps={{
                "aria-labelledby": "switch-list-label-boolean",
              }}
            />
          </ListItem>
          <TextField sx={hiddenStyle} name={name} id={`bool-${name}`} value={value} />
          <Translator text={helperText} />
        </FormControl>
      );
    }

    if (type === "file") {
      return (
        <FormControl margin="dense" fullWidth>
          <TextField
            sx={(type === "hidden" && hiddenStyle) || {}}
            disabled={isDisabled}
            margin="dense"
            id={id || name}
            name={name}
            InputProps={{
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  <Translator text={startAdornment} />
                </InputAdornment>
              ) : undefined,
              endAdornment: endAdornment ? (
                <InputAdornment position="end">
                  <Translator text={endAdornment} />
                </InputAdornment>
              ) : undefined,
              inputComponent: FileInput,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type={"number"}
            min={min}
            max={max}
            required={isRequired}
            inputMode={inputMode}
            inputProps={{
              type,
              accept,
              max,
              min,
              step,
              pattern,
              multiple,
              helperText,
              value: fileValue,
              setIsChanged,
              hiddenStyle,
              fileValue,
              fileType,
              setFileValue: setFieldFileValue,
              style: {
                width: "100%",
                height: "10rem",
                margin: "0.5rem",
                textIndent: "200%",
                backgroundColor: `#efefef`,
                borderRadius: "0.25rem",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              },
              onBlur: onBlurInput,
              readOnly: isReadOnly,
            }}
            onChange={onChange}
            error={error}
            helperText={<Translator text={helperText} />}
            label={<Translator text={title} />}
            fullWidth
          />
          <TextField
            sx={{
              ...hiddenStyle,
              position: "absolute",
              bottom: "4rem",
              left: "50%",
            }}
            type={"number"}
            required={isRequired}
            min={min}
            max={max}
            inputProps={{
              type: "number",
              required: isRequired,
              readOnly: isReadOnly,
              max,
              min,
              value: fileQuantity,
            }}
            value={fileQuantity}
          />
        </FormControl>
      );
    }

    if (type === "date") {
      return (
        <FormControl margin="dense" fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              disabled={isDisabled}
              onClose={onBlurInput}
              toolbarTitle={<Translator text="Select date" />}
              id={`date-${name}`}
              type={type}
              okButtonLabel="Submit"
              closeOnSelect={true}
              required={isRequired}
              disablePast={disablePast}
              disableFuture={disableFuture}
              maxDate={maxDate}
              minDate={minDate}
              value={value}
              onChange={setValue}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  label={<Translator text={title} />}
                  id={name}
                  required={isRequired}
                  error={error}
                  helperText={<Translator text={helperText} />}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      );
    }

    if (type === "time") {
      return (
        <FormControl margin="dense" fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes"]}
              inputFormat="HH:mm"
              mask="__:__"
              id={`time-${name}`}
              type={type}
              okButtonLabel="Submit"
              closeOnSelect={true}
              required={isRequired}
              value={value}
              onChange={setValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  label={<Translator text={title} />}
                  id={name}
                  required={isRequired}
                  error={error}
                  helperText={<Translator text={helperText} />}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      );
    }

    if (type === "datetime") {
      return (
        <FormControl margin="dense" fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              id={`time-${name}`}
              type={type}
              okButtonLabel="Submit"
              closeOnSelect={true}
              required={isRequired}
              value={value}
              onChange={setValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  label={<Translator text={title} />}
                  id={name}
                  required={isRequired}
                  error={error}
                  helperText={<Translator text={helperText} />}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      );
    }

    if (type === "schedule") {
      return (
        <FormControl margin="dense" fullWidth>
          <Schedule
            start={start}
            finish={finish}
            role={role}
            employeeId={employeeId}
            setPlanStart={setStart}
            setPlanFinish={setFinish}
            setEmployeeId={setEmployeeId}
          />
          <TextField sx={hiddenStyle} name={startName} id={startName} value={start && start.toISOString()} />
          <TextField sx={hiddenStyle} name={finishName} id={finishName} value={finish && finish.toISOString()} />
          <TextField sx={hiddenStyle} name={employeeIdName} id={employeeIdName} value={employeeId} />
        </FormControl>
      );
    }
    return (
      <TextField
        sx={(type === "hidden" && hiddenStyle) || {}}
        disabled={isDisabled}
        margin="dense"
        id={name}
        name={name}
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">
              <Translator text={startAdornment} />
            </InputAdornment>
          ) : undefined,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">
              <Translator text={endAdornment} />
            </InputAdornment>
          ) : undefined,
        }}
        type={type}
        required={isRequired}
        multiline={multiline}
        inputMode={inputMode}
        minRows={multiline ? 4 : 1}
        inputProps={{
          type,
          max,
          min,
          step,
          pattern,
          onBlur: onBlurInput,
          value,
          readOnly: isReadOnly,
        }}
        value={value}
        onChange={onChange}
        error={error}
        helperText={<Translator text={helperText} />}
        label={<Translator text={title} />}
        fullWidth
      />
    );
  }
);

export default Field;

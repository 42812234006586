import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Button, Box, Typography } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import useStores from "~/hooks";

const ContractButton = observer(({ contract, id, number }) => {
  const { contractStore } = useStores();

  const handleClick = useCallback(() => {
    // console.log({ id, number });
  }, [contract]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#1177d3" }}>
      <ArticleIcon />
      <Typography variant="h6">{number}</Typography>
    </Box>
    // <Button onClick={handleClick} variant="outlined" size="small" startIcon={<ArticleIcon />}>
    //   {number}
    // </Button>
  );
});

export default ContractButton;

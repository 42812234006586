import base64 from "base-64";

import { ApiBase } from "~/api";

/**
 * API аутентификации и авторизации.
 */
export default class AuthApiV0 extends ApiBase {
  /**
   * Войти в систему. Написан напрямую fetch т.к. в ApiBase.post прокинута аутентификация.
   * А здесь, на входе, заголовое Authtorization должен иметь особое значение.
   *
   * @param {object} loginData объект вида {login, password}
   */
  async login({ login, password }) {
    const url = "api/v0/auth/user-tokens";
    const authorizationHeader = `Basic ${base64.encode(`${login}:${password}`)}`;
    const response = await fetch(`${url}`, {
      method: "post",
      headers: { [this.AUTHORIZATION_HEADER]: authorizationHeader },
    });
    const userData = await response.json();
    const token = response.headers.get(this.AUTHORIZATION_HEADER);
    return [userData, token];
  }

  /**
   * Выйти из системы удалив токен аутентификации.
   *
   * @returns Response
   */
  async logout() {
    const url = "api/v0/auth/user-tokens";
    const headers = await this.delete(url);
    return headers;
  }
}

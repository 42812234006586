import { makeObservable, observable, action, computed } from "mobx";

import { createTheme } from "@mui/material/styles";
import * as locale from "@mui/material/locale";
import * as dataGridLocale from "@mui/x-data-grid";
import * as datePickerLocale from "@mui/x-date-pickers";

import Module from "./Module";
import { light } from "@mui/material/styles/createPalette";

/**
 * Стор состояние рабочей области.
 */
export default class WorkspaceStore {
  @observable mode;
  @observable lang;
  @observable currentViewSlug;
  //
  @observable root;

  constructor(root) {
    makeObservable(this);
    this.mode = "light"; // "dark" : "light")
    this.root = root;
    this.lang = "ru";
    this.currentViewSlug = localStorage.getItem("currentViewSlug") || this.modules[0].slug;
  }

  /**
   * Переключить цветовую схему.
   */
  @action toggleColorMode() {
    this.mode = this.mode === "dark" ? "light" : "dark";
  }

  /**
   * Установить slug текущего вида.
   */
  @action setCurrentView(slug) {
    this.currentViewSlug = slug;
    localStorage.setItem("currentViewSlug", slug);
  }

  /**
   * Построить и вернуть тему приложения.
   */
  @computed get theme() {
    const { lang, mode } = this;
    const { languageStore } = this.root;
    return createTheme(
      {
        palette: {
          mode,
          gray: { main: "#666", light: "#ccc", contrastText: "#222" },
          blue: { main: "#61a0d9", light: "#9fc5e8", contrastText: "#222" },
          green: { main: "#84b070", light: "#b6d7a8", contrastText: "#222" },
          orange: { main: "#ffb64c", light: "#ffe599", contrastText: "#222" },
          red: { main: "#ea9999", light: "#ea9999", contrastText: "#222" },
        },
      },
      locale[lang],
      dataGridLocale[lang],
      datePickerLocale[lang],
      {
        typography: { fontSize: 8 },
        components: {
          MuiDataGrid: {
            styleOverrides: {
              cell: {
                fontSize: 14, // Увеличить шрифт во всех таблицах
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              root: ({ ownerState, theme }) => {
                if (!ownerState.color) {
                  return {};
                }
                return { backgroundColor: theme.palette[ownerState.color].paper };
              },
              rippleBackgroundColor: "blue",
            },
          },
          MuiCard: {
            defaultProps: {
              variant: "outlined",
            },
            styleOverrides: {
              root: ({ ownerState, theme }) => {
                if (!ownerState.color) {
                  return {};
                }
                return { borderColor: theme.palette[ownerState.color].light };
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              variant: "outlined",
            },
          },
          MuiDialog: {
            defaultProps: {
              maxWidth: "md",
              fullWidth: true,
              scroll: "paper",
              disableEscapeKeyDown: true,
            },
          },
          MuiDialogContent: {
            styleOverrides: {
              overflow: "auto",
              root: {
                paddingTop: 0,
              },
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              title: ({ ownerState, theme }) => {
                if (!ownerState.color) {
                  return {};
                }
                return { color: theme.palette[ownerState.color].main };
              },
            },
          },
          MuiLocalizationProvider: {
            defaultProps: {
              localeText: {
                previousMonth: languageStore.translate({ text: "Previous month" }),
                nextMonth: languageStore.translate({ text: "Next month" }),
                openPreviousView: languageStore.translate({ text: "open previous view" }),
                openNextView: languageStore.translate({ text: "open next view" }),
                start: languageStore.translate({ text: "Start" }),
                end: languageStore.translate({ text: "End" }),
                cancelButtonLabel: languageStore.translate({ text: "Cancel" }),
                clearButtonLabel: languageStore.translate({ text: "Clear" }),
                okButtonLabel: languageStore.translate({ text: "OK" }),
                todayButtonLabel: languageStore.translate({ text: "Today" }),
                timeTableLabel: languageStore.translate({ text: "pick time" }),
                dateTableLabel: languageStore.translate({ text: "pick date" }),
              },
            },
          },
          MuiButton: {
            defaultProps: {
              disableElevation: true
            }
          }
        },
      }
    );
  }

  /**
   * Список всех модулей системы.
   */
  @computed get modules() {
    return [
      new Module("commercial-offers", "erpauth.use_module_commercial-offers"),
      new Module("contracts", "erpauth.use_module_contracts"),
      new Module("invoices", "erpauth.use_module_invoices"),
      // new Module("payments", "erpauth.use_module_payments"),
      new Module("clients", "erpauth.use_module_clients"),
      new Module("facilities", "erpauth.use_module_facilities"),
      new Module("workers", "erpauth.use_module_workers"),
      new Module("expenses", "erpauth.use_module_expenses"),
      new Module("reports", "erpauth.use_module_reports"),
      new Module("scanner-report", "erpauth.use_module_scanner-report"),
      new Module("scanners-working-shifts", "erpauth.use_module_scanners-working-shifts"),
      new Module("scanner", "erpauth.use_module_scanner"),
      new Module("scanner-schedule", "erpauth.use_module_scanner-schedule"),
      new Module("modeller-schedule", "erpauth.use_module_modeller-schedule"),
    ];
  }

  /**
   * Вернуть множество доступных пользователю модулей.
   */
  @computed get availableModules() {
    const { permissions } = this.root.authStore;
    const modules = new Set();
    this.modules.forEach((m) => {
      if (permissions.has(m.requiredPermission)) modules.add(m);
      else if (m.requiredPermission === "") modules.add(m);
    });
    return [...modules];
  }

  @computed get view() {
    return this.currentViewSlug;
  }
}

// const [mode, setMode] = useState(localStorage.getItem("mode") || (isDark ? "dark" : "light"));
// localStorage.setItem("mode", mode);

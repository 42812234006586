import React from "react";

import { CorporateFare, Cottage, Factory, Gite, Castle, Apartment } from "@mui/icons-material";

/**
 * Renders facility icon for type.
 */
export default function FacilityIcon({ kind }) {
  const icons = {
    building: <Factory />,
    house: <Gite />,
    flat: <Apartment />,
    premises: <CorporateFare />,
    townhouse: <Cottage />,
    other: <Castle />,
  };
  return icons[kind];
}

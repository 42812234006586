import { observable, action, runInAction, computed, makeObservable } from "mobx";

import { HttpClientError } from "./errors";

export default class ApiBase {
  AUTHORIZATION_HEADER = "Authorization";

  pendingRequests = 0;

  constructor(authStore) {
    makeObservable(this, {
      pendingRequests: observable,
      get: action,
      post: action,
      put: action,
      delete: action,
      isPending: computed,
    });
    this.authStore = authStore;
  }

  /**
   * Выполнить GET запрос.
   *
   * @param {string} url УРЛ на который шлём запрос
   * @param {object} parameters аргументы url-строки
   * @param {object} headers дополнительные заголовк
   * @returns
   */
  async get(url, parameters = {}, headers = {}) {
    runInAction(() => this.pendingRequests++);
    const params = new URLSearchParams(parameters);
    const response = await fetch(`${url}?${params}`, {
      method: "get",
      headers: { ...headers, [this.AUTHORIZATION_HEADER]: this.authStore.token },
    });
    if (response.status === 401) this.authStore.removeCredentials();
    runInAction(() => this.pendingRequests--);
    return [await response.json(), response.headers];
  }

  /**
   * Выполнить POST запрос.
   *
   * @param {string} url УРЛ на который шлём запрос
   * @param {object} payload тело запроса
   * @param {object} parameters аргументы url-строки
   * @param {object} headers дополнительные заголовки
   */
  async post(url, payload = {}, parameters = {}, headers = {}) {
    runInAction(() => this.pendingRequests++);
    const params = new URLSearchParams(parameters);
    const response = await fetch(`${url}?${params}`, {
      method: "post",
      headers: { [this.AUTHORIZATION_HEADER]: this.authStore.token, ...headers },
      body: JSON.stringify(payload),
    });
    if (response.status === 401) this.authStore.removeCredentials();
    if (response.status >= 400 && response.status < 500) {
      const { detail } = await response.json();
      throw new HttpClientError(response.status, detail);
    }
    // TODO: обработку 500 ошибки, там нет detail?! и нужно показать весь текст
    // ответа сервера
    runInAction(() => this.pendingRequests--);
    return [await response.json(), response.headers];
  }

  /**
   * Выполнить POST запрос с FormData.
   * 
   * @param {object} дикт с данными формы
   */
  async postFormData(url, data = {}, parameters = {}, headers = {}) {
    runInAction(() => this.pendingRequests++);
    const params = new URLSearchParams(parameters);
    var form = new FormData();
    for (const name in data) {
      form.append(name, data[name]);
    }
    const formData = {
      method: "POST",
      headers: {
        [this.AUTHORIZATION_HEADER]: this.authStore.token, ...headers
      },
      body: form,
      credentials: "include",
      // redirect: redirectError ? "error" : "follow",
    };

    const response = await fetch(`${url}?${params}`, formData);

    if (response.status === 401) this.authStore.removeCredentials();
    if (response.status >= 400 && response.status < 500) {
      const { detail } = await response.json();
      throw new HttpClientError(response.status, detail);
    }
    // TODO: обработку 500 ошибки, там нет detail?! и нужно показать весь текст
    // ответа сервера
    runInAction(() => this.pendingRequests--);
    return [await response.json(), response.headers];
  }

  /**
   * Выполнить PUT запрос.
   *
   * @param {string} url УРЛ на который шлём запрос
   * @param {object} payload тело запроса
   * @param {object} parameters аргументы url-строки
   * @param {object} headers дополнительные заголовки
   */
  // TODO: добавить обработку ошибок!
  async put(url, payload = {}, parameters = {}, headers = {}) {
    runInAction(() => this.pendingRequests++);
    const params = new URLSearchParams(parameters);
    const response = await fetch(`${url}?${params}`, {
      method: "put",
      headers: { [this.AUTHORIZATION_HEADER]: this.authStore.token, ...headers },
      body: JSON.stringify(payload),
    });
    if (response.status === 401) this.authStore.removeCredentials();
    runInAction(() => this.pendingRequests--);
    return [await response.json(), response.headers];
  }

  /**
   * Выполнить DELETE запрос.
   *
   * @param {string} url УРЛ на который шлём запрос.
   * @returns ответ сервера
   */
  async delete(url) {
    runInAction(() => this.pendingRequests++);
    const response = await fetch(url, {
      method: "delete",
      headers: { [this.AUTHORIZATION_HEADER]: this.authStore.token },
    });
    if (response.status === 401) this.authStore.removeCredentials();
    runInAction(() => this.pendingRequests--);
    return response.headers;
  }

  /**
   * Есть ли активный запрос в текущий момент.
   */
  get isPending() {
    return this.pendingRequests > 0;
  }
}

import React from "react";
import { observer } from "mobx-react";

import Form from "~/components/Form";

/**
 * Форма логина.
 */
const LoginForm = observer(({ handleLogin, isPending }) => {
  const config = {
    submitText: "Login",
    fields: [
      {
        name: "login",
        title: "Login",
        type: "text",
        viewConfig: "input",
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "password",
        title: "Password",
        type: "password",
        isRequired: true,
        isReadOnly: false,
        validate: false,
      },
    ],
  };

  return <Form config={config} onSubmit={handleLogin} noCancel={true} isPending={isPending} sx={{ pt: 2 }} />;
});

export default LoginForm;
